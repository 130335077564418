import * as actions from '../../actions';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { AxiosResponse } from 'axios';
import didNumbersApi from '../../services/didNumbers';
import {
    AssignDIDStatus,
    AssignDIDToAccountResponse,
    CancelDIDAssignmentResponse,
    CancelDIDStatus,
    CustomerDIDNumberType,
    CustomerNumberType,
} from '../../types/CustomerDIDNumber';
import { toast } from 'react-hot-toast';
import i18n from '../../../services/i18n';
import { AccountListRequest, AccountListResponse } from '../../types/Account';
import JSONFormData from '../../../utils/JSONFormData';
import { api } from '../../services/axios';
import { showErrorToast } from '../../../utils/showErrorToast';
import { APIErrorInterface, ReduxState } from '../../types';
import { Account, DID } from '../../../services/endpoints';
import { getDidNumbers as getDidNumbersDashboardSaga } from '../dashboard/saga';
import { getCountriesList, getGlobalCustomerInfo } from '../generic/saga';
import { ServiceFeatureName } from '../../types/ServiceFeature';
import { ExtensionType } from '../../types/Extension';
import { addMainService } from '../extensions/extensions/saga';

export function* getDidNumbers(
    action: ActionType<typeof actions.didNumbers.request>,
) {
    try {
        yield call(getCountriesList);
        const { session_id, csrf_token } = yield select((state) => state.auth);
        const { filters } = yield select((state) => state.didNumbers);

        filters.limit = action.payload?.limit || 10;
        filters.offset = action.payload?.offset || 0;

        if (action.payload?.initialCall) {
            yield getDidNumbersDashboardSaga();
            yield getGlobalCustomerInfo();
        }

        const callFilters = {
            ...filters,
            area_name:
                filters.area && filters.area !== 'Any'
                    ? filters.area
                    : undefined,
            country_name:
                filters.country !== undefined && filters.country !== 'Any'
                    ? filters.country
                    : undefined,
            number: filters.number ? `%${filters.number}%` : undefined,
        };

        const res: AxiosResponse<CustomerNumberType> = yield didNumbersApi.getCustomerNumbers(
            session_id,
            csrf_token,
            callFilters,
        );
        yield put(actions.didNumbers.success(res.data));

        for (const e of res.data.number_list) {
            if (e.i_account && e.extension_id) {
                const body = new JSONFormData(session_id, csrf_token);
                body.setParams({
                    i_account: e.i_account,
                    get_service_features: [
                        ServiceFeatureName.AutoAttendant,
                        ServiceFeatureName.UnifiedMessaging,
                    ],
                });

                const response: AxiosResponse<{
                    account_info: ExtensionType;
                }> = yield api.post(Account.GetAccountInfo, body);

                //@ts-ignore
                e.mainService = addMainService(response.data.account_info);
            }
        }
        yield put(actions.didNumberWithServices.success(res.data));
    } catch (error) {
        // @ts-ignore
        const apiError: APIErrorInterface = error?.response?.data;
        yield put(actions.didNumberWithServices.failure(apiError));
    }
}

export function* getAccountsToAssign(
    action: ActionType<typeof actions.didAccountsToAssign.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);
        const params: Partial<AccountListRequest> = {
            i_customer: action.payload.i_customer,
            free_of_extensions: 0,
            get_not_closed_accounts: 1,
            get_only_real_accounts: 1,
            get_total: 1,
            limit: 1000,
            offset: 0,
        };
        const body = new JSONFormData(session_id, csrf_token);
        body.setParams(params);

        const res: AxiosResponse<AccountListResponse> = yield api.post(
            Account.GetAccountList,
            body,
        );
        yield put(actions.didAccountsToAssign.success(res.data));
    } catch (e) {
        // @ts-ignore
        showErrorToast(e.response?.data?.faultstring);
    }
}

export function* assignDIDNumber(
    action: ActionType<typeof actions.assignDIDNumberToAccount.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);

        if (action.payload.previousAssigned) {
            const cancelResponse: AxiosResponse<CancelDIDAssignmentResponse> = yield didNumbersApi.cancelDIDAssignment(
                session_id,
                csrf_token,
                action.payload.previousAssigned,
            );

            if (cancelResponse.data.success == CancelDIDStatus.Fail) {
                i18n.t<string>(
                    'screens:didNumbers.didNumberCancelAssignmentText',
                );
            }
        }

        const assignResponse: AxiosResponse<AssignDIDToAccountResponse> = yield didNumbersApi.assignNumberToAccount(
            session_id,
            csrf_token,
            action.payload.assignTo,
        );
        yield put(
            actions.assignDIDNumberToAccount.success(assignResponse.data),
        );

        const msg =
            assignResponse.data.success == AssignDIDStatus.Ok
                ? i18n.t<string>('screens:didNumbers.didNumberAssignedText')
                : i18n.t<string>('screens:didNumbers.didNumberNotAssignedText');
        if (!action.payload.disableToasts) toast(msg);
        action.payload.callback && action.payload.callback();
    } catch (e) {
        // @ts-ignore
        showErrorToast(e.response?.data?.faultstring);
        yield put(actions.assignDIDNumberToAccount.failure());
    }
}

export function* cancelDIDNumberAssignet(
    action: ActionType<typeof actions.cancelDIDNumberAssignet.request>,
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);
    body.setParams(action.payload);

    try {
        const cancelResponse: AxiosResponse<CancelDIDAssignmentResponse> = yield api.post(
            DID.CancelDIDAssignment,
            body,
        );

        const msg =
            cancelResponse.data.success == CancelDIDStatus.Ok
                ? i18n.t<string>(
                      'screens:didNumbers.didNumberCancelAssignmentSuccess',
                  )
                : i18n.t<string>(
                      'screens:didNumbers.didNumberCancelAssignmentFailed',
                  );
        toast(msg);
        yield put(actions.cancelDIDNumberAssignet.success(cancelResponse.data));
        action.payload.callback && action.payload.callback();
    } catch (e) {
        // @ts-ignore
        showErrorToast(e.response?.data?.faultstring);
    }
}

export function* getAccountByIAccount(
    action: ActionType<typeof actions.getAccountByIAccount.request>,
) {
    try {
        const { session_id, csrf_token } = yield select((state) => state.auth);
        const body = new JSONFormData(session_id, csrf_token);
        body.setParams({
            id: action.payload.master_account_id,
        });

        const res: AxiosResponse<AccountListResponse> = yield api.post(
            'Account/get_account_list',
            body,
        );

        yield put(
            actions.getAccountByIAccount.success({
                assignedTo: res.data.account_list[0],
            }),
        );
    } catch (e) {
        // @ts-ignore
        showErrorToast(e);
    }
}

export function* getDIDNumbersByAccountID(i_account: number) {
    const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    body.setParams({ i_account: i_account });

    const numberResponse: AxiosResponse<CustomerNumberType> = yield api.post(
        DID.GetCustomerNumbers,
        body,
    );

    return numberResponse;
}

export function* getDidNumbersForSelect() {
    const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    const numberResponse: AxiosResponse<CustomerNumberType> = yield api.post(
        DID.GetCustomerNumbers,
        body,
    );
    yield put(
        actions.didNumbersForSelect.success({
            numbers: numberResponse.data.number_list,
        }),
    );
}

export function* getFilteredDidNumbers(
    action: ActionType<typeof actions.getFilteredDidNumbers.request>,
) {
    const { session_id, csrf_token } = yield select((state: ReduxState) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);
    const params: {
        area_name?: string;
        country_name?: string | null;
        number?: string;
    } = {};

    if (action.payload.area && action.payload.area !== 'Any')
        params.area_name = action.payload.area;
    if (
        action.payload.country !== undefined &&
        action.payload.country !== 'Any'
    )
        params.country_name = action.payload.country;
    if (action.payload.number) params.number = `%${action.payload.number}%`;

    body.setParams(params);

    const response: AxiosResponse<any> = yield api.post(
        DID.GetCustomerNumbers,
        body,
    );

    yield put(actions.didNumbers.success(response.data));

    for (const e of response.data.number_list) {
        if (e.i_account) {
            const body = new JSONFormData(session_id, csrf_token);
            body.setParams({
                i_account: e.i_account,
                get_service_features: [
                    ServiceFeatureName.AutoAttendant,
                    ServiceFeatureName.UnifiedMessaging,
                ],
            });

            const response: AxiosResponse<{
                account_info: ExtensionType;
            }> = yield api.post(Account.GetAccountInfo, body);

            //@ts-ignore
            e.mainService = addMainService(response.data.account_info);
        }
    }
    yield put(actions.didNumberWithServices.success(response.data));
}

export function* updateAccountDIDNumbers(
    oldDidNumbers: string[],
    newNumbers: string[],
    pool: CustomerDIDNumberType[],
    accountId: number,
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    const customerDidNumbers: {
        [key in string]: CustomerDIDNumberType;
    } = {};

    pool.forEach((v) => {
        customerDidNumbers[v.did_number] = v;
    });

    for (const v of newNumbers) {
        if (!oldDidNumbers.includes(v)) {
            body.setParams({
                i_did_number: customerDidNumbers[v].i_did_number,
                i_master_account: accountId,
            });
            yield api.post(DID.AssignDIDToAccount, body);
        }
    }

    for (const v of oldDidNumbers) {
        if (!newNumbers.includes(v)) {
            body.setParams({
                i_did_number: customerDidNumbers[v].i_did_number,
                i_master_account: accountId,
                dont_release_to_pool: 1,
            });
            yield api.post(DID.CancelDIDAssignment, body);
        }
    }
}

export function* deleteDIDNumber(
    action: ActionType<typeof actions.deleteDIDNumber.request>,
) {
    const { session_id, csrf_token } = yield select((state) => state.auth);
    const body = new JSONFormData(session_id, csrf_token);

    const { did_id, i_master_account } = action.payload;

    try {
        if (action.payload.i_master_account) {
            body.setParams({
                i_master_account: i_master_account,
                i_did_number: did_id,
                dont_release_to_pool: 0,
            });

            yield api.post(DID.CancelDIDAssignment, body);
        } else {
            body.setParams({ i_did_number: did_id });
            yield api.post(DID.DeleteNumber, body);
        }

        toast(i18n.t<string>('screens:didNumbers.deleteSuccess'));
        yield put(actions.deleteDIDNumber.success());

        const { filters } = yield select((state) => state.didNumbers);

        yield put(actions.didNumbers.request({ ...filters }));
        action.payload.callback?.();
    } catch (e) {
        // @ts-ignore
        showErrorToast(e.response?.data?.faultstring);
        yield put(actions.deleteDIDNumber.failure());
    }
}

export const didNumbersSaga = [
    takeLatest(actions.didNumbers.request, getDidNumbers),
    takeLatest(actions.didAccountsToAssign.request, getAccountsToAssign),
    takeLatest(actions.assignDIDNumberToAccount.request, assignDIDNumber),
    takeLatest(
        actions.cancelDIDNumberAssignet.request,
        cancelDIDNumberAssignet,
    ),
    takeLatest(actions.getAccountByIAccount.request, getAccountByIAccount),
    takeLatest(actions.didNumbersForSelect.request, getDidNumbersForSelect),
    takeLatest(actions.deleteDIDNumber.request, deleteDIDNumber),
    takeLatest(actions.getFilteredDidNumbers.request, getFilteredDidNumbers),
];
