import { ActionType, createReducer } from 'typesafe-actions';

import * as actions from '../../actions/auth';
import { ConfirmMfaConfigResponse, GetMfaConfigResponse, MfaConfig, MfaStatus, SessionIdPayload } from '../../actions/payloads';
import { APIErrorInterface } from '../../types';
import { SessionData } from '../../types/Session';

export interface AuthReducerStateType {
    session_id?: string | null;
    access_token?: string | null;
    csrf_token?: string | null;
    LogInActionsType?: string | null;
    login?: string | null;
    changePasswordActive: boolean;
    passwordRecovered: boolean;
    passwordChanged: boolean;
    email?: string | null;
    oldPassword?: string | null;
    errors?: APIErrorInterface | null;
    msg?: string | null;
    inProgress?: boolean;
    mfa_enabled?: number | null;
    mfa_verified?: number | null;
    mfaConfig?: MfaConfig | null;
    mfaVerifyError?: APIErrorInterface;
    waiting2FA?: SessionIdPayload;
    verifying2FaCode?: boolean;
    individualMfaConfig?: GetMfaConfigResponse | null;
    individualMfaConfigError?: APIErrorInterface;
    updateMfaConfigError?: ConfirmMfaConfigResponse | null;
    resetMfaConfigError?: ConfirmMfaConfigResponse | null;
}

export const initialState: AuthReducerStateType = {
    session_id: window.localStorage.getItem('session_id'),
    access_token: window.localStorage.getItem('access_token'),
    csrf_token: window.localStorage.getItem('csrf_token'),
    login: (JSON.parse(localStorage.getItem('user_info') || '{}') as SessionData)?.user,
    changePasswordActive: false,
    passwordRecovered: false,
    passwordChanged: false,
    email: null,
    oldPassword: null,
    errors: null,
    msg: null,
    inProgress: false,
    mfa_enabled: null,
    mfa_verified: null,
    mfaConfig: null,
    individualMfaConfig: null
};

export type LogInActionsType = ActionType<typeof actions>;

const signInReducer = createReducer<AuthReducerStateType, LogInActionsType>(
    initialState,
)
    .handleAction(actions.logIn.request, (state, action) => ({
        ...state,
        login: action.payload.login,
        inProgress: true,
    }))
    .handleAction(actions.logIn.success, (state, action) => {
        const { session_id, access_token, csrf_token, mfa_enabled, mfa_verified} = action.payload;
        return {
            ...state,
            session_id,
            access_token,
            csrf_token,
            errors: null,
            msg: null,
            inProgress: false,
            mfa_enabled,
            mfa_verified,
            mfaConfig: null
        };
    })
    .handleAction(actions.logIn.failure, (state, action) => {
        return {
            ...state,
            errors: action.payload.response?.data,
            inProgress: false,
        };
    })
    .handleAction(
        actions.sendRecoveryPasswordEmail.success,
        (state, action) => ({
            ...state,
            passwordChanged: !!action.payload.success,
            changePasswordActive: false,
            passwordRecovered: false,
            oldPassword: null,
            msg: 'mailMeSuccess',
        }),
    )
    .handleAction(
        actions.sendRecoveryPasswordEmail.failure,
        (state, action) => ({
            ...state,
            errors: action.payload.response?.data,
        }),
    )
    .handleAction(actions.passwordRecovery.success, (state, action) => ({
        ...state,
        passwordChanged: !!action.payload.success,
        changePasswordActive: false,
        passwordRecovered: true,
        oldPassword: null,
        msg: 'passwordRecoverySuccess',
    }))
    .handleAction(actions.passwordRecovery.failure, (state, action) => ({
        ...state,
        errors: action.payload.response?.data,
    }))
    .handleAction(actions.passwordChange.success, (state, action) => ({
        ...state,
        passwordChanged: !!action.payload.success,
        changePasswordActive: false,
        oldPassword: null,
        msg: 'passwordRecoverySuccess',
    }))
    .handleAction(actions.passwordChange.failure, (state, action) => ({
        ...state,
        errors: action.payload.response?.data,
    }))
    .handleAction(actions.logInPasswordExpired, (state, action) => ({
        ...state,
        login: action.payload.login,
        oldPassword: action.payload.password,
        changePasswordActive: true,
        errors: null,
    }))
    .handleAction(actions.loginClearErrors, (state) => ({
        ...state,
        errors: null,
        msg: null,
    }))
    .handleAction(actions.setEnteredLogin, (state, action) => ({
        ...state,
        login: action.payload.login,
    }))
    .handleAction(actions.signInFromAdmin.request, (state, action) => ({
        ...state,
        value: action.payload.value,
        inProgress: true,
    }))
    .handleAction(actions.signInFromAdmin.success, (state, action) => {
        const { session_id, access_token } = action.payload;
        return {
            ...state,
            session_id,
            access_token,
            errors: null,
            msg: null,
            inProgress: false,
        };
    })
    .handleAction(actions.signInFromAdmin.failure, (state, action) => {
        return {
            ...state,
            errors: action.payload.response?.data,
            inProgress: false,
        };
    })
    .handleAction(actions.generateMfaConfig.request, (state) => ({
        ...state,
        mfaConfig: null,
    }))
    .handleAction(actions.generateMfaConfig.success, (state, action) => ({
        ...state,
        mfaConfig: action.payload,
        inProgress: false,
    }))
    .handleAction(actions.generateMfaConfig.failure, (state) => ({
        ...state,
        mfaConfig: null,
        inProgress: false,
    }))
    .handleAction(actions.cleanupMfaConfig, (state) => ({
        ...state,
        mfaConfig: null,
        individualMfaConfig: null,
        updateMfaConfigError: null,
        resetMfaConfigError: null
    }))
    .handleAction(actions.confirmMfaConfig.request, (state) => ({
        ...state,
        mfaVerifyError: undefined,
    }))
    .handleAction(actions.confirmMfaConfig.success, (state) => ({
        ...state,
        mfaVerifyError: undefined,
    }))
    .handleAction(actions.confirmMfaConfig.failure, (state, action) => ({
        ...state,
        mfaVerifyError: action.payload,
    }))
    .handleAction(actions.twoFaValidationRequired, (state, action) => ({
        ...state,
        waiting2FA: action.payload,
        errors: null,
        inProgress: false,
    }))
    .handleAction(actions.validate2FaCode.request, (state) => ({
        ...state,
        mfaVerifyError: undefined,
        verifying2FaCode: true,
    }))
    .handleAction(actions.validate2FaCode.success, (state) => ({
        ...state,
        mfaVerifyError: undefined,
        waiting2FA: undefined,
        verifying2FaCode: undefined
    }))
    .handleAction(actions.validate2FaCode.failure, (state, action) => ({
        ...state,
        mfaVerifyError: action.payload,
        verifying2FaCode: undefined
    }))
    .handleAction(actions.getMfaConfig.request, (state, action) => ({
        ...state,
        individualMfaConfig: {
            mfa_used: state.individualMfaConfig?.mfa_used || MfaStatus.Disabled,
            mfa_configured: state.individualMfaConfig?.mfa_configured || 0,
            effective_mfa_used: state.individualMfaConfig?.effective_mfa_used,
            object: action.payload.object,
            i_object: action.payload.i_object,
        },
    }))
    .handleAction(actions.getMfaConfig.success, (state, action) => ({
        ...state,
        individualMfaConfig: {
            ...action.payload,
            object: state.individualMfaConfig?.object || '',
            i_object: state.individualMfaConfig?.i_object || -1,
        },
    }))
    .handleAction(actions.getMfaConfig.failure, (state, action) => ({
        ...state,
        individualMfaConfig: undefined,
        individualMfaConfigError: action.payload
    }))
    .handleAction(actions.updateMfaUsed.request, (state, action) => ({
        ...state,
        updateMfaConfigError: undefined,
        individualMfaConfig: {
            mfa_used: action.payload.use_mfa,
            mfa_configured: state.individualMfaConfig?.mfa_configured || 0,
            effective_mfa_used: state.individualMfaConfig?.effective_mfa_used,
            object: action.payload.object,
            i_object: action.payload.i_object,
        },
    }))
    .handleAction(actions.updateMfaUsed.success, (state, action) => ({
        ...state,
        individualMfaConfig: action.payload,
        mfaConfig: undefined,
        updateMfaConfigError: undefined
    }))
    .handleAction(actions.updateMfaUsed.failure, (state, action) => ({
        ...state,
        updateMfaConfigError: action.payload
    }))
    .handleAction(actions.resetMfa.request, (state) => ({
        ...state,
        resetMfaConfigError: undefined
    }))
    .handleAction(actions.resetMfa.success, (state) => ({
        ...state,
        resetMfaConfigError: undefined
    }))
    .handleAction(actions.resetMfa.failure, (state, action) => ({
        ...state,
        resetMfaConfigError: action.payload
    }))
    ;

export default signInReducer;
