import {makeStyles} from '@material-ui/core/styles';
import {Colors} from '../../styles/Colors';
import * as Yup from 'yup';
import i18n from '../../services/i18n';
import {UAListFileFormat, UploadUAListRequest} from '../../store/types/Devices';

const requiredFieldError = i18n.t('errors:common.emptyInput');
export const duplication = i18n.t('errors:ringGroups.duplication');

export const initialValues: UploadUAListRequest = {
    name: '',
    file: null,
    file_format: UAListFileFormat.CSV
};

export type UploadDeviceFileProps = {
    isModalOpen: boolean;
    setIsModalOpen: (value: boolean) => void;
    afterUpload?: () => void
};

export const useStyles = makeStyles(() => ({
    header: {
        '& h2': {
            fontSize: 18,
            fontWeight: 700,
        },
    },
    root: {
        '& .MuiDialogContent-root': {
            padding: 0,
            width: 535,
            height: 196
        },
        '& .MuiGrid-item': {
            margin: 0,
        },
    },
    itemsContainer: {
        backgroundColor: Colors.SmokeBackground,
        marginTop: 0,
        display: 'flex',
        width: '100%',
        height: '100%',
        padding: 0
    },
    itemsContainerStep123: {
        padding: '35px 31px 58px 40px'
    },
    itemsContainerStep456: {
        padding: 23.47
    },
    fileDragAndDropBox: {
        border: '0.76px dashed rgba(0, 0, 0, 0.25)',
        borderRadius: '7.62px',
        width: '100%',
        height: '100%'
    },
    uploadBox: {
        padding: '12px 18px',
        width: 'calc(100% - 36px)',
        height: 'calc(100% - 24px)',
        cursor: 'pointer',
        display: 'flex'
    },
    importTop: {
        whiteSpace: 'nowrap',
        marginLeft: 17,
        marginTop: 10,
        fontWeight: 400,
        fontSize: 16,
        color: Colors.Black,
        maxWidth: 230,
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    importBottom: {
        whiteSpace: 'nowrap',
        marginLeft: 17,
        fontWeight: 400,
        fontSize: 16,
        color: Colors.Gray6
    },
    uploadIcon: {
        marginTop: 25,
        marginLeft: 15,
        marginRight: 8,
        fill: Colors.Gray11,
        fillOpacity: 1,
    },
    browseButton: {
        marginTop: 19,
        color: Colors.White,
        width: 121,
        height: 40
    },
    lastColumn: {
        marginLeft: 'auto'
    },
    loaderContainer: {
        display: 'flex',
        marginTop: 43,
        marginLeft: 212,
        flexDirection: 'column',
        '& span': {
            marginTop: 20
        }
    },
    uploadDoneText: {
        fontWeight: 400,
        fontSize: 16,
        textAlign: 'center',
        color: Colors.Gray5,
        width: '100%'
    },
    uploadDoneTextLink: {
        fontWeight: 400,
        fontSize: 16,
        textAlign: 'center',
        color: Colors.Gray5,
        width: '100%',

        '& a': {
            textDecoration: 'none',
            color: Colors.Link
        }
    },
    resultsContainer: {
        width: '100%',
        height: '100%'
    },
    resultsIcon: {
        width: 70.76,
        height: 70.76,
        marginLeft: 208.3,
        transform: 'scale(1.577)'
    }
}));

export const UploadFileValidationSchema = Yup.object().shape({
    file: Yup.mixed().required(requiredFieldError),
    fileName: Yup.string()
        .required(requiredFieldError)
        .test('duplication', duplication, function (value, x) {
            const result = this?.options?.context?.items.find(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (i: any) => i.name !== 'Upload new file' && i.name === value,
            );
            return !result;
        }),
});

export const getReadableSize = (size: number) => {
    const byte = i18n.t('units:short.byte');
    const kb = i18n.t('units:short.kilobyte');
    const mb = i18n.t('units:short.megabyte');
    const bytesInKb = 1024.0;

    if (size < bytesInKb) {
        return size + ' ' + byte;
    } else if (size < (bytesInKb * bytesInKb)) {
        let v = (parseFloat(size + '') / bytesInKb).toFixed(1);
        if (v.endsWith('.0')) {
            v = v.substring(0, v.length - 2);
        }
        return v + ' ' + kb;
    }
    let w = (parseFloat(size + '') / (bytesInKb * bytesInKb)).toFixed(1);
    if (w.endsWith('.0')) {
        w = w.substring(0, w.length - 2);
    }
    return w + ' ' + mb;
}