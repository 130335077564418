import DataGrid from '../../../components/DataGrid/DataGrid';
import {TransactionTableRow} from "./TransactionList.utils";
import React, {useMemo, useState} from 'react';
import {Fade} from '@material-ui/core';
import {Colors} from '../../../styles/Colors';
import classNames from 'classnames';
import {Doughnut, Chart} from 'react-chartjs-2';
import {useTranslation} from 'react-i18next';
//@ts-ignore
import AnimatedNumber from 'animated-number-react';
import type * as AllChart from 'chart.js';
import {TransactionChartProps, useStyles} from "./TransactionChart.utils";

export const formatTotalSum = (v: number) => {
    return Number(v.toFixed(2)).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        minimumIntegerDigits: 1,
    }).replace(/,/gi, ' ');
}

const TransactionChart: React.FC<TransactionChartProps> = (
    {
        columns,
        inputData,
        isLoading,
        currency,
        dataQa,
        hideFooter,
        message,
        showNoDataMsg
    }) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const [lastMousePoint, setLastMousePoint] = useState({x: -1, y: -1});

    const total: number = inputData.filter(e => !e.isSummary).reduce((a, b) => a + (b.total || 0), 0);
    const dataSum: number = inputData.filter(e => !e.isSummary).reduce((a, b) => a + (Math.abs(b.amount) || 0), 0);

    const dataset = useMemo(() => {
        return {
            labels: inputData.filter(e => !e.isSummary).map((v) => v.type),
            datasets: [
                {
                    data: inputData.filter(e => !e.isSummary).map((e) => Math.abs(e.amount)),
                    backgroundColor: inputData.filter(e => !e.isSummary).map((e) => e.color),
                    borderColor: inputData.filter(e => !e.isSummary).map(() => Colors.White),
                },
            ]
        } as AllChart.ChartData;
    }, [inputData]);

    console.log(inputData, dataset)

    const capitalizeFirstLetter = (value: string) => {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onMouseMoveOverCanvas = (e: any) => {
        const rect = e.target.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        setLastMousePoint({x: x, y: y});
    }

    const tooltipPlugin = Chart?.registry?.getPlugin('tooltip');

    if (tooltipPlugin) {
        //@ts-ignore
        tooltipPlugin.positioners.middle = (elements) => {
            const model = elements[0]?.element;
            return {
                x: model?.x + (model?.width || 0) / 2 - 30,
                y: model?.y + (model?.height || 0) / 2 - 13,
            };
        };
    }

    const getOrCreateTooltip = (chart: any) => {
        const toolTipId = 'chart-tooltip-id';
        let tooltipEl = chart.canvas.parentNode.querySelector('#' + toolTipId);

        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = toolTipId;
            tooltipEl.style.background = Colors.Text;
            tooltipEl.style.borderRadius = '4px';
            tooltipEl.style.padding = '4px';

            tooltipEl.style.color = Colors.White;
            tooltipEl.style.opacity = 1;
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.transition = 'all .1s ease';

            const table = document.createElement('table');
            table.style.margin = '0px';

            tooltipEl.appendChild(table);
            chart.canvas.parentNode.appendChild(tooltipEl);
        }

        return tooltipEl;
    };

    const externalTooltipHandler = (context: any, chartData: AllChart.ChartData, currency: string) => {
        const {chart, tooltip} = context;

        const tooltipEl = getOrCreateTooltip(chart);

        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        if (tooltip.body) {
            const element = document.createElement('div');

            const dataIndex = tooltip.dataPoints[0].dataIndex;
            const objes = (chartData.datasets[0]?.data as number[]) || [];
            const sum = objes.reduce((a: number, b: number) => a + (b || 0), 0);

            if (sum > 0) {
                const value = objes[dataIndex] || 0;
                const label = ((chartData.labels || [])[dataIndex] || '') + '';
                const percent = (parseFloat(value + '') * parseFloat('100.0')) / parseFloat(sum + '');
                const subTitleText = formatTotalSum(value) + ' ' + currency + ' (' + formatTotalSum(percent) + '%)';

                if (label.length > 1) {
                    const titleLabel = document.createElement('div');
                    titleLabel.style.whiteSpace = 'nowrap';
                    titleLabel.appendChild(document.createTextNode(label));
                    element.appendChild(titleLabel);

                    const subTitle = document.createElement('div');
                    subTitle.style.whiteSpace = 'nowrap';
                    subTitle.appendChild(document.createTextNode(subTitleText));
                    element.appendChild(subTitle);
                }
            }

            while (tooltipEl?.firstChild) {
                tooltipEl.firstChild.remove();
            }

            tooltipEl.appendChild(element);
        }

        tooltipEl.style.opacity = 1;
        if (lastMousePoint && lastMousePoint.x >= 0 && lastMousePoint.y >= 0) {
            tooltipEl.style.left = lastMousePoint.x + 'px';
            tooltipEl.style.top = lastMousePoint.y + 'px';
        }
        tooltipEl.style.fontSize = '12px';
        tooltipEl.style.lineHeight = '18px';
        tooltipEl.style.padding = '4px 8px';
        tooltipEl.style.zIndex = '5';
        tooltipEl.style.height = '36px';
        tooltipEl.style.width = 'fit-content';
        tooltipEl.style.fontFamily = 'Roboto';
        tooltipEl.style.fontStyle = 'normal';
        tooltipEl.style.fontWeight = 400;
        tooltipEl.style.color = '#F5F7F8';
    };

    return (
        <div className={classes.tabContainer}>
            <div
                className={classes.chartContainerInternal} onMouseOver={(e) => onMouseMoveOverCanvas(e)}
                onMouseEnter={(e) => onMouseMoveOverCanvas(e)}
                onMouseMove={(e) => onMouseMoveOverCanvas(e)}
            >
                <div className={classNames(
                    classes.chartPlaceholder,
                    isLoading && classes.chartLoadingBackground,
                    dataSum === 0 && classes.chartEmptyBackground,
                )}/>
                {!isLoading && (
                    <div>
                        <Doughnut
                            className={classes.chart}
                            data={dataset}
                            height={200}
                            width={200}
                            options={{
                                responsive: true,
                                //@ts-ignore
                                cutout: 76,
                                //@ts-ignore
                                borderWidth: 1,
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                    tooltip: {
                                        enabled: false,
                                        //@ts-ignore
                                        position: 'middle',
                                        external: (context) =>
                                            externalTooltipHandler(context, dataset, currency)
                                    },
                                },
                            }}
                        />
                        <div className={classes.sumHeader}>
                            {capitalizeFirstLetter(t('common:total'))}, {currency}
                        </div>
                        <div className={classes.sumValue}>
                            <AnimatedNumber
                                className={classes.sumText}
                                value={dataSum}
                                formatValue={formatTotalSum}
                            />
                        </div>
                    </div>
                )}
                <Fade in={isLoading} timeout={1000} appear={isLoading}>
                    <div className={classes.loadingLabel}>
                        {t('common:loading')}
                    </div>
                </Fade>
            </div>
            <DataGrid<TransactionTableRow>
                dataQa={dataQa}
                columns={columns}
                data={inputData}
                rowCount={total}
                hideFooter={hideFooter}
                classes={{
                    header: classes.header,
                    tableContainer: classes.tableContent
                }}
                loading={isLoading}
                message={message}
                showNoDataImg={false}
                showNoDataMsg={showNoDataMsg}
            />
        </div>
    );
}

export default TransactionChart;