import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import history from './../../history';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from './../../store/actions';
import { ReduxState } from '../../store/types';
import { ApiLoginPayload } from '../../store/services/auth-api';
import LoginForm from '../../components/Forms/LoginForm/LoginForm';
import { Routes } from '../../routes/routes';
import AuthWrapper from '../../components/AuthWraper/AuthWrapper';
import usePageTitle from '../../hooks/usePageTitle';
import { Enable2FADialogProps } from './Enable2FADialog.utils';
import Enable2FADialog from './Enable2FADialog';
import Validate2FADialog from './Validate2FADialog';
import { Validate2FADialogProps } from './Validate2FADialog.utils';

const SignIn = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    usePageTitle();

    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [passedLogin, setPassedLogin] = useState('');
    const [show2FADialog, setShow2FADialog] = useState<Enable2FADialogProps>({
        isOpen: false
    });
    const [show2FAValidateDialog, setShow2FAValidateDialog] = useState<Validate2FADialogProps>({
        isOpen: false
    });
    const { errors, changePasswordActive, login, oldPassword, mfaConfig, waiting2FA} = useSelector(
        (state: ReduxState) => state.auth,
    );

    if (changePasswordActive && login && oldPassword) {
        history.push(Routes.PasswordExpiration);
    }
    
    const dispatchLoginAction = (payload: ApiLoginPayload) => {
        dispatch(
            actions.logIn.request({
                login: payload.login,
                password: payload.password,
            }),
        );
    };

    const handleOnCloseSnackbar = () => {
        dispatch(actions.loginClearErrors());
    };

    const handleOnClickOnLoginRecovery = (login?: string) => {
        dispatch(actions.setEnteredLogin({ login: login || '' }));
        history.push(Routes.PasswordRecovery);
    };

    useEffect(() => {
        if(login && !mfaConfig && !waiting2FA) {
            setPassedLogin(login);
        } else if (mfaConfig) {
            setShow2FADialog({
                isOpen: true,
                mfaConfig: mfaConfig
            });
        } else if (waiting2FA) {
            setShow2FAValidateDialog({
                isOpen: true,
                sessionConfig: waiting2FA
            });
        }
    }, [login, mfaConfig, waiting2FA]);

    return (
        <AuthWrapper
            title={t('screens:signIn.welcome')}
            message={snackbarMessage}
            onSnackbarClose={handleOnCloseSnackbar}
        >
            <LoginForm
                onSubmitAction={dispatchLoginAction}
                onRecoveryClick={handleOnClickOnLoginRecovery}
                validateOnChange={false}
                setSnackbarMessage={setSnackbarMessage}
                apiErrors={errors ? errors : undefined}
                passedLogin={passedLogin}
            />
            <Enable2FADialog {...show2FADialog} />
            <Validate2FADialog {...show2FAValidateDialog} />
        </AuthWrapper>
    );
};

export default SignIn;
