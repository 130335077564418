import React, {useEffect} from 'react';
import {Provider, useSelector, useDispatch} from 'react-redux';
import {Redirect, Router, Switch} from 'react-router';

import history from './history';
import './services/i18n';
import {initReduxStore, store} from './store';
import BaseRoute from './routes/BaseRoute';
import ProtectedRoute from './routes/ProtectedRoute';
import SignIn from './views/SignIn/SignIn';
import {ReduxState} from './store/types';
import {Dashboard} from './views/Dashboard/Dashboard';
import {ExtensionsList} from './views/Extensions/List/ExtensionsList';
import {RingGroupsList} from './views/RingGroups/RingGroupsList';
import Toaster from './components/Toaster/Toaster';
import AddRingGroup from './views/RingGroups/AddRingGroup';
import RingGroupDetails from './views/RingGroups/RingGroupDetails';
import DidNumbersList from './views/DidNumbers/DidNumbersList';
import {CallHistoryComponent} from './views/CallHistory/CallHistory';
import {SidebarProvider} from './hooks/useSidebarContext';
import MyCompanyDetails from './views/MyCompany/MyCompanyDetails';
import {PhoneBookList} from './views/PhoneBook/PhoneBookList';
import {CallRecordingsList} from './views/CallRecordings/CallRecordingsList';
import DevicesList from './views/Devices/DevicesList';
import DeviceDetails from './views/Devices/DeviceDetails';
import AutoAttendantDetails from './views/AutoAttendants/Details/AutoAttendantsDetails';
import PortalUsersDetails from './views/PortalUsers/PortalUsersDetails';
import FaxMailboxDetails from './views/FaxMailboxes/Details/FaxMailboxDetails';
import AutoAttendantsList from './views/AutoAttendants/List/AutoAttendantsList';
import AutoAttendantsMenuDetails from './views/AutoAttendants/AutoAttendantsMenuDetails';
import FaxMailboxesList from './views/FaxMailboxes/List/FaxMailboxesList';
import {PublicRoutes, Routes} from './routes/routes';
import CallSettingsDetails from './views/CallSettings/CallSettingsDetails';
import Config from './config.json';
import MyProfile from './views/MyProfile/MyProfile';
import EditPortalUser from './views/PortalUsers/EditPortalUser';
import ExtensionDetails from './views/Extensions/ExtensionDetails/ExtensionDetails';
import PasswordRecovery from './views/PasswordRecovery/PasswordRecovery';
import ResetPassword from './views/ResetPassword/ResetPassword';
import PasswordExpiration from './views/PasswordExpiration/PasswordExpiration';
import NotFound from './views/NotFound/NotFound';
import BillingDetails from './views/Billing/BillingDetails';
import TagManager from 'react-gtm-module';
import {actions} from './store';
import * as crypto from 'crypto';
import Zendesk from './components/Zendesk/Zendesk';
import {useDefaultScreenBasedOnPermissions} from './hooks/usePermissions';
import NotSupportedBrowser, {isBrowserSupported} from './NotSupportedBrowser';

initReduxStore(Config.CUSTOMER_API_URL);

const App = () => {
    return (
        <Provider store={store}>
            <AppNavigation/>
            <Zendesk/>
        </Provider>
    );
};

const AppNavigation = () => {
    const dispatch = useDispatch();
    const {session_id} = useSelector((state: ReduxState) => state.auth);
    const {pathname} = history.location;
    const i_customer = useSelector(
        (state: ReduxState) => state.generic.sessionData?.i_customer,
    );
    const reload = useSelector(
        (state: ReduxState) => state.generic.sessionData?.reload
    );
    const {customer} = useSelector((state: ReduxState) => state.myProfile);
    const {isLoading} = useSelector((state: ReduxState) => state.permissions);

    const defaultRoute = useDefaultScreenBasedOnPermissions();

    const userId =
        customer?.i_individual != undefined && customer?.i_individual !== -1
            ? `individual${customer?.i_individual.toString()}`
            : `customer${i_customer?.toString()}`;

    const tagManagerArgs = {
        dataLayer: {
            iCustomer: i_customer,
            userId: crypto
                .createHash('md5')
                .update(userId || '')
                .digest('hex'),
        },
    };

    Config.GTM_TAG_ID.length > 0 && TagManager.dataLayer(tagManagerArgs);

    useEffect(() => {
        const isPublicRoute = Object.values(PublicRoutes).includes(pathname);
        const params = new URLSearchParams((window.location.search || ''));
        if((pathname || '').toLocaleLowerCase() === Routes.Default.toLocaleLowerCase()
            && params.has('data_for_portal')) {
            const value = params.get('data_for_portal') || '';
            //location.replace(Routes.Dashboard);
            dispatch(actions.signInFromAdmin.request({value}));
        } else if (!session_id && !isPublicRoute) {
            history.push(Routes.SignIn);
        } else if (isPublicRoute && session_id) {
            history.push(Routes.Default);
        }
    }, [session_id, pathname]);

    useEffect(() => {
        if (session_id) {
            dispatch(actions.getMyProfileDetails.request());
            dispatch(actions.getPortalPermissions.request());
            dispatch(actions.getGlobalCustomerInfo.request());
        }
    }, [session_id]);

    useEffect(() => {

        if (session_id && reload) {
            location.reload();
        }

    }, [reload, session_id]);

    const isSupported = isBrowserSupported(window.navigator.userAgent);
    if(!isSupported) {
        return (
            <NotSupportedBrowser />
        );
    }

    if (session_id && isLoading) {
        return (
            <div role="progressbar"/>
        );
    }

    return (
        <Router history={history}>
            <Toaster/>
            <SidebarProvider>
                {!session_id ? (
                    <Switch>
                        <>
                            <BaseRoute
                                exact
                                path={Routes.SignIn}
                                component={SignIn}
                            />
                            <BaseRoute
                                path={Routes.ResetPassword}
                                component={ResetPassword}
                            />
                            <BaseRoute
                                path={Routes.PasswordRecovery}
                                exact
                                component={PasswordRecovery}
                            />
                            <BaseRoute
                                path={Routes.PasswordExpiration}
                                exact
                                component={PasswordExpiration}
                            />
                        </>
                    </Switch>
                ) : (
                    <Switch>
                        <ProtectedRoute
                            exact
                            path={Routes.Default}
                            component={() => <Redirect to={defaultRoute}/>}
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.Dashboard}
                            component={Dashboard}
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.Extensions}
                            component={ExtensionsList}
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.ExtensionsDetails}
                            component={(props: any) => (
                                <ExtensionDetails id={props.match.params.id}/>
                            )}
                        />
                        <ProtectedRoute
                            path={Routes.RingGroups}
                            component={RingGroupsList}
                            exact
                        />
                        <ProtectedRoute
                            path={Routes.AddRingGroup}
                            component={AddRingGroup}
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.RingGroupsDetails}
                            component={(props: any) => (
                                <RingGroupDetails id={props.match.params.id}/>
                            )}
                        />
                        <ProtectedRoute
                            path={Routes.DidNumbers}
                            component={DidNumbersList}
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.Devices}
                            component={DevicesList}
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.DevicesDetails}
                            component={(props: any) => (
                                <DeviceDetails id={props.match.params.id}/>
                            )}
                        />
                        <ProtectedRoute
                            path={Routes.CallActivity}
                            component={CallHistoryComponent}
                        />
                        <ProtectedRoute
                            path={Routes.MyProfile}
                            component={MyProfile}
                        />
                        <ProtectedRoute
                            path={Routes.MyCompany}
                            component={MyCompanyDetails}
                        />
                        <ProtectedRoute
                            path={Routes.CallRecordings}
                            component={CallRecordingsList}
                        />
                        <ProtectedRoute
                            path={Routes.PortalUsers}
                            component={PortalUsersDetails}
                        />
                        <ProtectedRoute
                            path={Routes.EditPortalUser}
                            component={(props: any) => (
                                <EditPortalUser id={props.match.params.id}/>
                            )}
                        />
                        <ProtectedRoute
                            path={Routes.Billing}
                            component={BillingDetails}
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.FaxMailboxes}
                            component={FaxMailboxesList}
                        />
                        <ProtectedRoute
                            path={Routes.PhoneBook}
                            component={PhoneBookList}
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.AutoAttendants}
                            component={AutoAttendantsList}
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.AutoAttendantsDetails}
                            component={(props: any) => (
                                <AutoAttendantDetails
                                    id={props.match.params.id}
                                />
                            )}
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.FaxMailboxesDetails}
                            component={(props: any) => (
                                <FaxMailboxDetails id={props.match.params.id}/>
                            )}
                        />
                        <ProtectedRoute
                            exact
                            path={Routes.AutoAttendantsMenu}
                            component={(props: any) => (
                                <AutoAttendantsMenuDetails
                                    autoAttendantId={
                                        props.match.params.autoAttendantId
                                    }
                                    menuId={props.match.params.menuId}
                                />
                            )}
                        />
                        <ProtectedRoute
                            path={Routes.CallSettings}
                            component={CallSettingsDetails}
                        />
                        <ProtectedRoute component={NotFound}/>
                    </Switch>
                )}
            </SidebarProvider>
        </Router>
    );
};

export default App;