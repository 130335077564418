import {
    CreateExtensionFaultCode,
    ExtensionType,
    UpdateExtensionFaultCode,
} from '../store/types/Extension';
import { AutoAttendantListItem } from '../store/types/AutoAttendant';
import i18n from '../services/i18n';
import { ExtensionsListItem } from '../store/reducers/extensions/extensions/reducer';
import { CustomerDIDNumberType } from '../store/types/CustomerDIDNumber';
import { CustomerAgreementCondition } from '../store/types/CustomerAgreementCondition';
import { CustomerInfoDetails } from '../store/types/CustomerInfo';
import {
    CustomerIndividualInfo,
    MyProfile,
} from '../store/actions/myProfile/payloads';
import { APIErrorInterface } from '../store/types';
import { InvoiceListEntity, ServiceInfo } from '../store/types/Billing';
import { InvoiceTableRow } from '../views/Billing/Invoices/InvoiceList.utils';
import { roundUp } from './roundUp';
import dayjs from '../../src/services/customDayJs';

import { TransactionTableRow } from '../views/Billing/Transactions/TransactionList.utils';
import { CallHistory } from '../store/types/CallHistory';
import { CreditAdjustmentsTableRowInterface } from '../components/TransactionTables/IndividualTable/CreditAdjustmentsTable.utils';
import { VoiceCallsTableRowInterface } from '../components/TransactionTables/VoiceCalls/VoiceCalls.utils';
import { DataServiceTableRowInterface } from '../components/TransactionTables/DataService/DataService.utils';
import { QuantityBasedTableRowInterface } from '../components/TransactionTables/QuantityBased/QuantityBased.utils';
import { SubscriptionsTableRowInterface } from '../components/TransactionTables/IndividualTable/Subscriptions.utils';
import { WiFiTableRowInterface } from '../components/TransactionTables/DataService/WiFi.utils';
import { MessagingTableRowInterface } from '../components/TransactionTables/Messaging/Messaging.utils';
import { AggregatedHistoryTableRowInterface } from '../components/TransactionTables/IndividualTable/AggregatedHistory.utils';
import { TaxesTableRowInterface } from '../components/TransactionTables/IndividualTable/Taxes.utils';
import { ConferencingTableRowInterface } from '../components/TransactionTables/IndividualTable/Conferencing.utils';
import { DidUsageTableRowInterface } from '../components/TransactionTables/DidUsage/DidUsage.utils';
import { iPTVTableRowInterface } from '../components/TransactionTables/IPTV/iPTV.utils';
import { MeasuredServiceTableRowInterface } from '../components/TransactionTables/MeasuredService/MeasuredService.utils';
import { MobileNetworkProvisioningTableRowInterface } from '../components/TransactionTables/MobileNetworkProvisioning/MobileNetworkProvisioning.utils';
import camelCase from 'camelcase';
import { CompanyInfoFormType } from '../components/Forms/MyProfile/CompanyInfo/utils';

export interface DurationFromSec {
    string: string;
    h: number;
    m: number;
    s: number;
    duration: string;
}

export type AdditionalElement = {
    default_value?: string;
    i_custom_field?: number;
    mandatory?: 'Y' | 'N';
    name?: string;
    personal_data?: 'Y' | 'N';
    properties?: string;
    type?: string;
    value?: string;
    visible_to_end_user?: 'Y' | 'N';
};

export const getDurationFromSec = (seconds: number) => {
    const duration = dayjs.duration(seconds, 'seconds');
    const { s, m, h } = getLocalisationToTimeUnits();

    let format = `H[${h}] m [${m}] s [${s}]`;

    if (seconds < 60) {
        format = `s [${s}]`;
    } else if (seconds > 60 && seconds < 3600) {
        format = `m [${m}] s [${s}]`;
    }

    return {
        string: duration.format(format),
        duration: duration.format('HH:mm:ss'),
        h: duration.get('hours'),
        m: duration.get('minute'),
        s: duration.get('seconds'),
    };
};

const descCldLocalisation = (desc?: string, cld?: string) => {
    const camelDesc = camelCase(desc || '');
    const camelCld = camelCase(cld || '');
    const descLocal = i18n.t<string>(
        'screens:billing.description.' + camelDesc,
        desc || '',
    );
    const cldLocal = i18n.t<string>(
        'screens:billing.cld.' + camelCld,
        cld || '',
    );

    return {
        desc: descLocal,
        cld: cldLocal,
    };
};

const getLocalisationToTimeUnits = () => {
    const hLocal = i18n.t<string>('units:time.hours');
    const mLocal = i18n.t<string>('units:time.minutes');
    const sLocal = i18n.t<string>('units:time.seconds');

    return {
        h: hLocal,
        m: mLocal,
        s: sLocal,
    };
};

const getDuration = (endTime: string, startTime: string): string => {
    const { s, m, h } = getLocalisationToTimeUnits();
    const diff = dayjs(endTime).diff(dayjs(startTime), 'seconds');

    return dayjs
        .duration(diff || 0, 'seconds')
        .format(`H [${h}] m [${m}] s [${s}]`);
};

export const mapToItem = (o: ExtensionType) => {
    const item: AutoAttendantListItem = {
        id: o.id,
        id_pin: o.id,
        extension_id: o.extension_id,
        name:
            o.extension_name ||
            i18n.t<string>('screens:autoAttendants.unnamed'),
        numbers: (o.did_number && [o.did_number]) || [],
        i_account: o.i_account,
        i_customer: o.i_customer,
        auto_attendant: o.auto_attendant,
        fax_only_mode: o.fax_only_mode,
        serviceFeatures: o.service_features,
        i_product: o.i_product,
        // @ts-ignore
        account_info: {
            email: o.email,
            sip_status: o.sip_status,
            blocked: o.blocked,
            sip_agent: o.sip_agent,
            i_account: o.i_account,
            country: o.country,
            h323_password: o.h323_password,
            um_domain: o.um_domain,
            alias_did_number_list: o.alias_did_number_list,
        },
        delete_enable: !!o.extension_id,
    };

    return item;
};

export const makeAutoAttendantItem = (
    numbers: CustomerDIDNumberType[],
    account: ExtensionType,
    ext?: ExtensionsListItem,
) => {
    const didNumbers = account?.did_number
        ? [...numbers.map((num) => num.did_number), account.did_number]
        : [...numbers.map((num) => num.did_number)];

    const item: AutoAttendantListItem = {
        id: account.id,
        id_pin: account.id,
        extension_id: account.extension_id,
        name: account.extension_name,
        numbers: didNumbers,
        i_account: account.i_account,
        i_customer: account.i_customer,
        auto_attendant: account.auto_attendant,
        fax_only_mode: account.fax_only_mode,
        serviceFeatures: account.service_features,
        i_c_ext: ext?.i_c_ext,
        // @ts-ignore
        account_info: {
            email: account.email,
            sip_status: account.sip_status,
            blocked: account.blocked,
            sip_agent: account.sip_agent,
            i_account: account.i_account,
            country: account.country,
            h323_password: account.h323_password,
            um_domain: account.um_domain,
            i_product: account.i_product,
            product_name: account.product_name,
            assigned_addons: account.assigned_addons,
            did_number: account.did_number,
            alias_did_number_list: account.alias_did_number_list,
        },
        delete_enable: !!account.extension_id,
    };

    return item;
};

export const addEnableToDelete = (
    account: AutoAttendantListItem[],
    products?: CustomerAgreementCondition[],
) => {
    return account.map((o) => {
        if (
            o.extension_id &&
            products?.find((p) => p.i_product == o.i_product)
        ) {
            return {
                ...o,
                delete_enable: true,
            };
        }

        return { ...o, delete_enable: false };
    });
};

export const makeMyProfileByCustomerIndividualInfo = (
    o: CustomerIndividualInfo,
) => {
    const {
        login,
        email,
        i_time_zone,
        lang,
        i_customer,
        password,
        i_individual,
    } = o;
    return {
        login: login || '',
        email: email || '',
        i_customer: i_customer || -1,
        lang: lang || '',
        i_individual: i_individual,
        iTimeZone: i_time_zone || -1,
        password: password || '',
    } as MyProfile;
};

export const makeMyProfileFromCustomerInfoDetails = (
    o: CustomerInfoDetails,
) => {
    const { login, email, i_ui_time_zone, i_lang, i_customer, password } = o;
    return {
        login: login || '',
        email: email || '',
        i_customer: i_customer || -1,
        lang: i_lang || '',
        i_individual: -1,
        iTimeZone: i_ui_time_zone || -1,
        password: password || '',
    };
};

export function errorTransformer(errors?: APIErrorInterface) {
    if (
        errors?.faultcode == UpdateExtensionFaultCode.ExtHGNumberInUse ||
        errors?.faultcode == CreateExtensionFaultCode.ExtHGNumberInUse
    ) {
        return {
            extensionNumber: i18n.t<string>(`errors:extensions.numberInUse`),
        };
    }

    if (
        errors?.faultcode == UpdateExtensionFaultCode.DuplicateId ||
        errors?.faultcode == CreateExtensionFaultCode.DuplicateId
    ) {
        return {
            extensionNumber: i18n.t<string>(
                `errors:extensions.numberDuplicated`,
            ),
        };
    }

    return undefined;
}

export function transactionEntityTransformer(
    item: ServiceInfo,
): TransactionTableRow {
    return {
        id: item.i_service.toString(),
        amount: Math.abs(item.charged_amount),
        charged: item.charged_quantity / item.ratio,
        isInteger: item.charged_quantity % item.ratio == 0,
        total: item.total,
        type: i18n.t<string>(
            'enums:standardServiceName.' + item.i_service,
            item.name,
        ),
        isSummary: false,
        unit: i18n.t<string>('units:long.' + item.rate_unit, item.rate_unit),
        iService: item.i_service,
        iServiceType: item.i_service_type,
        ratio: item.ratio,
        unitShort: i18n.t<string>(
            'units:short.' + item.rate_unit,
            item.rate_unit,
        ),
        isSpending: item.charged_amount >= 0,
        color:item.color
    };
}

export function invoiceEntityTransformer(
    item: InvoiceListEntity,
): InvoiceTableRow {
    const status =
        item.is_void == 'Y'
            ? i18n.t<string>('screens:billing.8')
            : i18n.t<string>('screens:billing.' + item.i_invoice_status);

    const i_status = item.is_void == 'Y' ? 8 : item.i_invoice_status;

    return {
        fileExist: item.is_pdf_exists,
        number: item.invoice_number,
        id: item.i_invoice,
        status: status,
        i_status: i_status,
        due_date: item.due_date,
        issued_at: item.issue_date,
        paid: roundUp(item.amount_paid, 2).toFixed(2),
        period_total: roundUp(item.amount_net - item.amount_paid, 2).toFixed(2),
        period_from: item.period_from,
        period_to: item.period_to,
        boldPeriodTotal: item.amount_net > 0 && item.amount_paid == 0,
        currency: item.iso_4217,
    };
}

export function creditAdjustmentsEntityTransformer(
    item: CallHistory,
): CreditAdjustmentsTableRowInterface {
    const translation = descCldLocalisation(item.description, item.CLD);

    return {
        id: item.i_xdr.toString(),
        accountId: item.account_id,
        chargedAmount: item.charged_amount,
        comment: translation.cld,
        connectTime: item.connect_time,
        description: translation.desc,
    };
}

export function subscriptionsEntityTransformer(
    item: CallHistory,
): SubscriptionsTableRowInterface {
    const translation = descCldLocalisation(item.description, item.CLD);

    return {
        id: item.i_xdr.toString(),
        accountId: item.account_id,
        subscription: translation.cld,
        description: translation.desc,
        start: item.connect_time,
        end: item.disconnect_time,
        chargedAmount: item.charged_amount,
    };
}

export function conferencingEntityTransformer(
    item: CallHistory,
): ConferencingTableRowInterface {
    const translation = descCldLocalisation(item.description, item.CLD);

    return {
        id: item.i_xdr.toString(),
        accountId: item.account_id,
        celler: item.CLI || i18n.t<string>('common:anonymous'),
        destination: translation.cld,
        description: translation.desc,
        connectTime: item.connect_time || '',
        chargedTime: getDurationFromSec(item.charged_quantity || 0),
        chargedAmount: item.charged_amount,
    };
}

export function taxesEntityTransformer(
    item: CallHistory,
): TaxesTableRowInterface {
    const translation = descCldLocalisation(item.description, item.CLD);

    return {
        id: item.i_xdr.toString(),
        tax: translation.cld,
        description: item.CLI,
        start: item.connect_time,
        end: item.disconnect_time,
        chargedAmount: item.charged_amount,
    };
}

export function aggregatedHistoryEntityTransformer(
    item: CallHistory,
): AggregatedHistoryTableRowInterface {
    const translation = descCldLocalisation(item.description, item.CLD);

    return {
        id: item.i_xdr.toString(),
        accountId: item.account_id,
        description: translation.desc,
        start: item.connect_time,
        end: item.disconnect_time,
        chargedAmount: item.charged_amount,
    };
}

export function didUsageEntityTransformer(
    item: CallHistory,
): DidUsageTableRowInterface {
    const translation = descCldLocalisation(item.description, item.CLD);

    return {
        id: item.i_xdr.toString(),
        accountId: item.account_id,
        didNumber: translation.cld,
        description: translation.desc,
        start: item.connect_time,
        end: item.disconnect_time,
        chargedAmount: item.charged_amount,
    };
}

export function measuredServiceEntityTransformer(
    item: CallHistory,
    ratio = 1,
): MeasuredServiceTableRowInterface {
    const translation = descCldLocalisation(item.description, item.CLD);

    return {
        id: item.i_xdr.toString(),
        accountId: item.account_id,
        description: translation.desc,
        start: item.connect_time,
        end: item.disconnect_time,
        units: (item.charged_quantity || 0) / ratio,
        chargedAmount: item.charged_amount,
        cld: item.CLD || ''
    };
}

export function iPTVEntityTransformer(
    item: CallHistory,
): iPTVTableRowInterface {
    const translation = descCldLocalisation(item.description, item.CLD);

    return {
        id: item.i_xdr.toString(),
        accountId: item.account_id || '',
        description: translation.desc,
        orderTime: item.connect_time,
        chargedAmount: item.charged_amount,
    };
}

export function messagingEntityTransformer(
    item: CallHistory,
    ratio = 1,
): MessagingTableRowInterface {
    const translation = descCldLocalisation(item.description, item.CLD);

    return {
        id: item.i_xdr.toString(),
        accountId: item.account_id,
        to: item.CLD || '',
        country: item.country || '',
        description: translation.desc,
        date: item.connect_time,
        quantity: (item.charged_quantity || 0) / ratio,
        chargedAmount: item.charged_amount,
    };
}

export function mobileNetworkProvisioningEntityTransformer(
    item: CallHistory,
    ratio = 1,
): MobileNetworkProvisioningTableRowInterface {
    const translation = descCldLocalisation(item.description, item.CLD);

    return {
        id: item.i_xdr.toString(),
        accountId: item.account_id,
        description: translation.desc,
        date: item.connect_time,
        quantity: (item.charged_quantity || 0) / ratio,
        chargedAmount: item.charged_amount,
    };
}

export function quantityBasedEntityTransformer(
    item: CallHistory,
    ratio = 0,
): QuantityBasedTableRowInterface {
    return {
        id: item.i_xdr.toString(),
        accountId: item.account_id,
        description: item.description,
        chargedAmount: item.charged_amount,
        quantity: (item.charged_quantity || 0) / ratio,
        date: item.connect_time,
    };
}

export function voiceCallsEntityTransformer(
    item: CallHistory,
): VoiceCallsTableRowInterface {
    const duration = getDurationFromSec(item.charged_quantity || 0);
    const translation = descCldLocalisation(item.description, item.CLD);

    return {
        id: item.i_xdr.toString(),
        accountId: item.account_id,
        celler: item.CLI,
        destination: translation.cld,
        description: translation.desc,
        connectTime: item.connect_time,
        chargedTime: duration.string,
        chargedAmount: item.charged_amount,
    };
}

export function DataServiceEntityTransformer(
    item: CallHistory,
    ratio = 0,
): DataServiceTableRowInterface {
    const translation = descCldLocalisation(item.description, item.CLD);

    return {
        id: item.i_xdr.toString(),
        accountId: item.account_id,
        description: translation.desc,
        connectTime: item.connect_time,
        sessionDuration: getDuration(
            item.disconnect_time || '',
            item.connect_time || '',
        ),
        disconnectTime: item.disconnect_time,
        volume: (item.charged_quantity || 0) / ratio,
        chargedAmount: item.charged_amount,
    };
}

export function WiFiEntityTransformer(
    item: CallHistory,
): WiFiTableRowInterface {
    const translation = descCldLocalisation(item.description, item.CLD);

    return {
        id: item.i_xdr.toString(),
        accountId: item.account_id,
        description: translation.desc,
        connectTime: item.connect_time,
        sessionDuration: getDuration(
            item.disconnect_time || '',
            item.connect_time || '',
        ),
        disconnectTime: item.disconnect_time,
        chargedAmount: item.charged_amount,
    };
}

export function getCompanyInfoTransformer(details: CustomerInfoDetails, 
        custom_fields?: any,
        copyAdditionalElement?: AdditionalElement[]) {

    const additionalDetails = custom_fields?.map(
        (e: AdditionalElement) =>
            e.value !== undefined ? e : { ...e, value: '' },
    );

    return {
        name: details.name || '',
        companyName: details.companyname || '',
        salutation: details.salutation || '',
        firstName: details.firstname || '',
        middleName: details.midinit || '',
        lastName: details.lastname || '',
        email: details.email || '',
        fax: details.faxnum || '',
        phone: details.phone1 || '',

        country: details.country || '',
        city: details.city || '',
        addressLine1: details.baddr1 || '',
        addressLine2: details.address_line_2 || '',
        provinceState: details.state || '',
        postalCode: details.zip || '',

        contactPerson: details.cont2 || '',
        additionalPhone: details.phone2 || '',
        additionalDetails: copyAdditionalElement ?? additionalDetails,
        amount: details.iso_4217,
        dateFormat: ''
    } as CompanyInfoFormType;
}
