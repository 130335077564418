import React, {useState, useMemo, useEffect, useCallback} from 'react';
import {IconButton} from '@material-ui/core';
import {Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {actions} from '../../store';
import {ReduxState} from '../../store/types';
import {useStyles, EditPortalUserDetailsProps} from './editPortalUserUtils';
import {ChevronLeft} from '@material-ui/icons';
import Loader from '../../components/Loader/Loader';
import {Colors} from '../../styles/Colors';
import {
    nameMaxLengthError,
    requiredFieldError,
    emailValidError,
    SecurityValidationSchema,
    CreateNewPortalUserFormData,
} from '../../components/PortalUsers/utils';
import PortalUserSettings from '../../components/PortalUsers/Settings/PortalUserSettings';
import PortalUserSecurity from '../../components/PortalUsers/Security/PortalUserSecurity';
import history from '../../history';
import {Routes} from '../../routes/routes';
import {calculateStatus} from './utils';
import dayjs from '../../services/customDayJs';
import AlertDialog from '../../components/AlertDialog/AlertDialog';
import {DialogButton} from '../../components/AlertDialog/DialogContainer';
import {getTabNumber} from '../../utils/getTabNumber';
import * as Yup from 'yup';
import {ConfigData} from '../../store/types/ConfigData';
import usePageTitle from '../../hooks/usePageTitle';
import _ from 'lodash';
import DetailsWrapper from '../../components/DetailsWraper/DetailsWrapper';
import {useRawPermissions} from "../../hooks/usePermissions";
import {generateTabs} from "../../utils/generateTabs";
import {Permission} from "../../store/types/Permission";
import PermissionProvider from "../../components/PermissionProvider/PermissionProvider";
import {convertUtcToUserLocalTime} from "../../utils/dateWithTimezoneConversion";
import NotFound from '../NotFound/NotFound';

const USER_NOT_FOUND_API_ERROR_CODE = 'Server.Customer.i_individual.not_found';

const EditPortalUser: React.FC<EditPortalUserDetailsProps> = ({id}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    usePageTitle();

    const permissions = useRawPermissions();

    const {isLoading, editedPortlUser, isFormSaving, apiErrors} = useSelector(
        (state: ReduxState) => state.company,
    );
    const {roleList} = useSelector((state: ReduxState) => state.company);

    const {timeZonesList, languagesList} = useSelector(
        (state: ReduxState) => state.generic,
    );

    const config = useSelector<ReduxState, ConfigData | undefined>(
        (state) => state.generic?.configData,
    );

    const timezoneOffset = useSelector<ReduxState, number>(
        (state) => state.generic.sessionData?.tz_offset || 0,
    );

    const userDateFormat = useSelector<ReduxState, string>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info?.out_date_format ||
            '',
    );
    const [tabIndex, setTabIndex] = useState(getTabNumber([0, 1, 2]));
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

    const CreateNewPortalUserValidationSchema = Yup.object().shape({
        role: Yup.string().required(requiredFieldError).nullable(),
        password: Yup.string()
            .test(
                'passLength',
                t(`screens:portalUsers.portalPassword`, {
                    value: config?.Accounts.CreditAccMinServicePasswdLength,
                }),
                function (value) {
                    if (
                        value !== undefined &&
                        config?.Accounts.CreditAccMinServicePasswdLength
                    ) {
                        return (
                            value.length >=
                            Number(
                                config?.Accounts
                                    .CreditAccMinServicePasswdLength,
                            )
                        );
                    }

                    return true;
                },
            )
            .max(32, nameMaxLengthError)
            .required(requiredFieldError),
        login: Yup.string()
            .matches(
                /(^[A-Za-z0-9-_@.]*$)/,
                t('errors:portalUsers.portalRegex'),
            )
            .required(requiredFieldError),
        email: Yup.string().email(emailValidError).notRequired(),
        activationDate: Yup.string()
            .test('past', (value, {createError, path}) => {
                if (!value) {
                    return true;
                }

                const date = dayjs(value, 'YYYY-MM-DD');
                const today = dayjs().startOf('date');

                const convertedValue = convertUtcToUserLocalTime(
                    value,
                    timezoneOffset,
                    userDateFormat,
                );

                return date >= today
                    ? true
                    : createError({
                        path,
                        message: t('errors:portalUsers.activationDate', {
                            value: convertedValue,
                        }),
                    });
            })
            .nullable(),
        expirationDate: Yup.string()
            .test('past', (value, {createError, path}) => {
                if (!value) {
                    return true;
                }

                const date = dayjs(value, 'YYYY-MM-DD');
                const today = dayjs().startOf('date');

                const convertedValue = convertUtcToUserLocalTime(
                    value,
                    timezoneOffset,
                    userDateFormat,
                );

                return date >= today
                    ? true
                    : createError({
                        path,
                        message: t('errors:portalUsers.activationDate', {
                            value: convertedValue,
                        }),
                    });
            })
            .test(
                'dobY',
                t('errors:portalUsers.expirationDateAfterActivation'),
                (value, ctx) => {
                    const expired = value
                        ? dayjs(value, 'YYYY-MM-DD').toDate()
                        : null;
                    const activation = Yup.ref('activationDate')
                        ? dayjs(
                            ctx.parent.activationDate,
                            'YYYY-MM-DD',
                        ).toDate()
                        : null;

                    if (!expired || !activation || !ctx.parent.activationDate) {
                        return true;
                    }

                    return activation < expired;
                },
            )
            .nullable()
            .notRequired(),
    });

    const formValidationSchema = CreateNewPortalUserValidationSchema.concat(
        SecurityValidationSchema,
    );

    const initialValues = useMemo(
        () => ({
            login: editedPortlUser?.customer_individual_info.login || '',
            password: editedPortlUser?.customer_individual_info.password || '',
            role:
                roleList?.find(
                    (e) =>
                        e.i_role ===
                        editedPortlUser?.customer_individual_info.i_role,
                )?.name || '',
            email: editedPortlUser?.customer_individual_info.email || '',
            activationDate:
                editedPortlUser?.customer_individual_info.activation_date ||
                null,
            expirationDate:
                editedPortlUser?.customer_individual_info.expiration_date ||
                null,
            timezone:
                timeZonesList.find(
                    (e) =>
                        e.i_time_zone ===
                        editedPortlUser?.customer_individual_info.i_time_zone,
                )?.time_zone_name || '',
            language:
                languagesList?.find(
                    (e) =>
                        e.iso_639_1 ===
                        editedPortlUser?.customer_individual_info.lang,
                )?.name || '',
            ipAddresses: editedPortlUser?.customer_individual_info
                .login_allowed_ip_list
                ? editedPortlUser.customer_individual_info.login_allowed_ip_list.map(
                    (e, index) => {
                        return {
                            isValueRequired: !!e.ip,
                            id: index,
                            value: e.ip,
                        };
                    },
                )
                : [
                    {
                        id: 0,
                        value: '',
                    },
                ],
        }),
        [editedPortlUser],
    );

    const {login} = useSelector((state: ReduxState) => state.auth);
    const isTheUserEqualsCurrent = useMemo(() => {
        return (login || '').toUpperCase() === (initialValues?.login || '').toUpperCase();
    }, [login, initialValues?.login]);

    const onSubmitForm = useCallback(
        (form: CreateNewPortalUserFormData) => {
            dispatch(
                actions.editPortalUser.request({
                    initialValues: {
                        ...initialValues,
                        activationDate: initialValues.activationDate
                            ? dayjs(initialValues.activationDate || '').format(
                                'YYYY-MM-DD',
                            )
                            : null,
                        expirationDate: initialValues.expirationDate
                            ? dayjs(initialValues.expirationDate || '').format(
                                'YYYY-MM-DD',
                            )
                            : null,
                    },
                    changedValues: {
                        ...form,
                        activationDate: form.activationDate
                            ? dayjs(form.activationDate || '').format(
                                'YYYY-MM-DD',
                            )
                            : null,
                        expirationDate: form.expirationDate
                            ? dayjs(form.expirationDate || '').format(
                                'YYYY-MM-DD',
                            )
                            : null,
                    },
                    redirectTab: tabIndex,
                    i_individual: id,
                }),
            );
        },
        [initialValues, id, tabIndex],
    );

    useEffect(() => {
        if(id && (!editedPortlUser || editedPortlUser?.customer_individual_info?.i_individual?.toString() !== id?.toString())) {
            dispatch(actions.getPortalUserInfo.request({i_individual: id}));
        }

    }, [id, editedPortlUser]);

    const backToList = () => {
        history.push(Routes.PortalUsers);
    };

    const onDelete = () => {
        setIsRemoveModalOpen(true);
    };

    const removePortalUser = () => {
        dispatch(
            actions.deletePortalUser.request({
                i_individual: id,
                callback: backToList,
            }),
        );
    };

    if (isLoading) {
        return (
            <div className={classes.loader}>
                <Loader dataQa="edit-ring-group-sending-loader"/>
            </div>
        );
    }
    
    if (apiErrors?.faultcode === USER_NOT_FOUND_API_ERROR_CODE) {
        return (
            <NotFound />
        );
    }

    const top = (
        <div className={classes.headerWrapper}>
            <div className={classes.extensionHeader}>
                <IconButton style={{marginRight: 10}} onClick={backToList}>
                    <ChevronLeft htmlColor={Colors.Text}/>
                </IconButton>
                <label>{t('screens:portalUsers.portalUser')}</label>
                <p className={classes.subTitle}>{initialValues.login}</p>
            </div>
            <div className={classes.statusWrapper}>
                <p>{t('common:status')}</p>
                <p className={classes.status}>
                    {
                        calculateStatus({
                            activation_date: initialValues.activationDate,
                            expiration_date: initialValues.expirationDate,
                        }).status
                    }
                </p>
            </div>
        </div>
    );

    const {tabNames, tabs} = generateTabs(
        [
            {
                title: t('common:settings'),
                permission:
                Permission.MyCompany.PortalUsers.PortalUsersDetails.PortalUsersSettings.value,
                tab: <PortalUserSettings/>,
            },
            {
                title: t('screens:portalUsers.security'),
                permission:
                Permission.MyCompany.PortalUsers.PortalUsersDetails.Security.value,
                tab: (
                    <PortalUserSecurity key={'tab-2'}/>
                ),
            },
        ],
        permissions,
    );


    return (
        <Formik
            initialValues={initialValues}
            //@ts-ignore
            validate={(values: any) => {
                try {
                    formValidationSchema.validateSync(values, {
                        abortEarly: false,
                        context: values,
                    });
                } catch (error: any) {
                    if (error.name !== 'ValidationError') {
                        throw error;
                    }

                    return error.inner.reduce(
                        (errors: any, currentError: any) => {
                            errors = _.set(
                                errors,
                                currentError.path,
                                currentError.message,
                            );
                            return errors;
                        },
                        {},
                    );
                }

                return {};
            }}
            onSubmit={onSubmitForm}
            enableReinitialize
            validateOnChange={false}
        >
            {({submitForm, dirty, values}) => (
                <>
                    <DetailsWrapper
                        tabs={tabs}
                        top={
                            <PermissionProvider
                                permission={
                                    Permission.MyCompany.PortalUsers.PortalUsersDetails.value
                                }
                            >
                                {top}
                            </PermissionProvider>
                        }
                        tabsNames={tabNames}
                        defaultTabIndex={tabIndex}
                        onTabChange={(_, index) => setTabIndex(index)}
                        showActionButtons
                        saveEnable={dirty}
                        preventIsOpen={dirty && !isRemoveModalOpen}
                        onBackPress={backToList}
                        onSavePress={submitForm}
                        onDeletePress={onDelete}
                        preventSavePress={() => onSubmitForm(values)}
                        deleteText={t(
                            'screens:portalUsers.deletePortalUserButton',
                        )}
                        deleteEnable={!isTheUserEqualsCurrent}
                        savePermission={
                            Permission.MyCompany.PortalUsers.PortalUsersDetails.value
                        }
                        deletePermission={
                            Permission.MyCompany.PortalUsers.PortalUsersDetails.DeletePortalUser.value
                        }
                    >
                        {isFormSaving && (
                            <Loader
                                dataQa="edit-ring-group-sending-loader"
                                absolutePosition
                            />
                        )}
                        <AlertDialog
                            isOpen={isRemoveModalOpen}
                            dataQa="remove-ringgroup-modal"
                            contentClass="medium-width-modal-delete"
                            hideHeader={true}
                            className={classes.dialog}
                            description={t(
                                'screens:portalUsers.deletePortalUser',
                                {name: initialValues.login},
                            )}
                            dialogActionsButtons={[
                                <DialogButton
                                    key="cancel"
                                    label={t('common:cancel')}
                                    onClick={() => setIsRemoveModalOpen(false)}
                                />,
                                <DialogButton
                                    key="delete"
                                    label={t('common:delete')}
                                    className={classes.primaryModalButton}
                                    onClick={removePortalUser}
                                />,
                            ]}
                        />
                    </DetailsWrapper>
                </>
            )}
        </Formik>
    );
};

export default EditPortalUser;
