import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../store/types';
import { actions } from '../../../store';
import { useTranslation } from 'react-i18next';
import { PaginationMode } from '../../../components/DataGrid/types';
import DataGrid from '../../../components/DataGrid/DataGrid';
import { useStyles, generateColumns, ActiveCallsFiltersProps, initialValues, DropDownDictionaryItem } from './ActiveCalls.utils';
import ActiveCallsInfo from '../../../components/Calls/ActiveCallsInfo';
import { SipCall, SipCallState, SipCallType } from '../../../store/actions/ringgroups/payloads';
import { ENABLE_NOTIFICATIONS_ACCESS_DENIED, GET_SIP_CALLS_ACCESS_DENIED } from '../../../store/sagas/ringgroups/saga';
import AccessDenied from '../../../components/PermissionProvider/AccessDenied';
import {Grid} from '@material-ui/core';
import { useFormik } from 'formik';
import Button from '../../../components/Button/Button';
import SelectField from '../../../components/SelectField/SelectField';

export const ActiveCalls: React.VFC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    const { ringGroupsList, isLoadingSipCalls, sipCallsList, sipCallsApiError, accounts } = useSelector(
        (state: ReduxState) => state.ringgroups,
    );

    const [filtered, setFiltered] = useState<SipCall[] | undefined>();

    const timezoneOffset = useSelector<ReduxState, number>(
        (state) => state.generic.sessionData?.tz_offset || 0,
    );

    const userDateTimeFormat = useSelector<ReduxState, string>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info
                ?.out_date_time_format || '',
    );

    const filterItems = useCallback((filters: ActiveCallsFiltersProps) => {
        if(!filters) return sipCallsList;
        return sipCallsList
            ?.filter(e => !filters.state || e.state === filters.state)
            ?.filter(e => !filters.type || e.type === filters.type)
            ?.filter(e => !filters.ringGroup || (e.callee?.huntgroup_id || e.caller?.huntgroup_id) === filters.ringGroup)
        || [];
    }, [sipCallsList]);

    useEffect(() => {
        dispatch(actions.ringGroupsList.request({
            limit: 1000,
            offset: 0
        }));
        dispatch(actions.getSipCallsList.request({}));
        dispatch(actions.getRingGroupsAccountList.request());
    }, []);

    const columns = useMemo(() => generateColumns(
        accounts,
        t,
        classes,
        timezoneOffset,
        userDateTimeFormat,
    ), [accounts]);
    
    const stateSelectList = useMemo(
        () => {
            const init: DropDownDictionaryItem<SipCallState | undefined>[] = [{
                name: t('common:any'),
                value: undefined
            }];
            const arr = Object.values(SipCallState)
            .filter(e => e === SipCallState.Trying ||
                e === SipCallState.Ringing ||
                e === SipCallState.Connected ||
                e === SipCallState.Holding
            )
            .map((v) => ({
                name: t(`enums:sipCallState.${v}`),
                value: v,
            } as DropDownDictionaryItem<SipCallState>));
            arr.map(e => init.push(e));
            return init;
        }, []
    );
    
    const typeSelectList = useMemo(
        () => {
            const init: DropDownDictionaryItem<SipCallType | undefined>[] = [{
                name: t('common:any'),
                value: undefined
            }];
            const arr = Object.values(SipCallType).map((v) => ({
                name: t(`enums:sipCallType.${v}`),
                value: v,
            } as DropDownDictionaryItem<SipCallType | undefined>));
            arr.map(e => init.push(e));
            return init;
        }, [],
    );
    
    const ringGroupsSelectList = useMemo(
        () => {
            const init: DropDownDictionaryItem<string>[] = [{
                name: t('common:any'),
                value: undefined
            }];
            ringGroupsList?.items?.map(e => {
                init.push({
                    name: e.id + ' - ' + e.name,
                    value: e.id
                })
            });
            return init;
        }, [ringGroupsList],
    );

    const {
        values,
        handleSubmit,
        setFieldValue,
    } = useFormik<ActiveCallsFiltersProps>({
        initialValues,
        onSubmit: () => {
            dispatch(actions.getSipCallsList.request({}));
            dispatch(actions.getRingGroupsAccountList.request());
        },
        enableReinitialize: true,
        validateOnChange: false,
    });

    useMemo(() => {
        setFiltered(filterItems(values));
    }, [sipCallsList, values]);

    if(sipCallsApiError?.faultcode === ENABLE_NOTIFICATIONS_ACCESS_DENIED
        || sipCallsApiError?.faultcode === GET_SIP_CALLS_ACCESS_DENIED) {
        return <AccessDenied />;
    }

    return (
        <>
            <form
                onSubmit={handleSubmit}
                autoComplete="off"
                data-testid="call-history-form"
            >
                <Grid item className={classes.filtersContainer}>
                    <Grid item className={classes.itemsContainer}>
                        <Grid item className={classes.inputsContainer}>
                            <SelectField
                                id="state"
                                label={t('screens:calls.callState')}
                                getOptionLabel={(option: DropDownDictionaryItem<SipCallState>) => {
                                    return option.name;
                                }}
                                onChange={(_, option: DropDownDictionaryItem<SipCallState>) => {
                                    setFieldValue('state', option.value);
                                }}
                                items={stateSelectList ?? []}
                                value={
                                    stateSelectList.find((v) => v.value === values.state)
                                }
                                getOptionSelected={(
                                    option: DropDownDictionaryItem<SipCallState>,
                                    value: DropDownDictionaryItem<SipCallState>,
                                ) => value.value === option.value}
                                dataQa={'current-calls-select-field-state'}
                                skipPermission
                                disableClearable
                            />
                            <SelectField
                                id="state"
                                label={t('screens:calls.callDirection')}
                                getOptionLabel={(option: DropDownDictionaryItem<SipCallType | undefined>) => {
                                    return option.name;
                                }}
                                onChange={(_, option: DropDownDictionaryItem<SipCallType | undefined>) => {
                                    setFieldValue('type', option.value);
                                }}
                                items={typeSelectList ?? []}
                                value={
                                    typeSelectList.find((v) => v.value === values.type)
                                }
                                getOptionSelected={(
                                    option: DropDownDictionaryItem<SipCallState | undefined>,
                                    value: DropDownDictionaryItem<SipCallState | undefined>,
                                ) => value.value === option.value}
                                dataQa={'current-calls-select-field-type'}
                                skipPermission
                                disableClearable
                            />
                            <SelectField
                                id="ringGroup"
                                label={t('screens:calls.ringGroup')}
                                getOptionLabel={(option: DropDownDictionaryItem<string>) => {
                                    return option.name;
                                }}
                                onChange={(_, option: DropDownDictionaryItem<string>) => {
                                    setFieldValue('ringGroup', option.value);
                                }}
                                items={ringGroupsSelectList ?? []}
                                value={ringGroupsSelectList.find((v) => v.value === values.ringGroup)}
                                getOptionSelected={(
                                    option: DropDownDictionaryItem<string>,
                                    value: DropDownDictionaryItem<string>,
                                ) => value.value === option.value}
                                dataQa={'current-calls-select-field-ring-group'}
                                skipPermission
                                disableClearable
                            />
                        </Grid>
                        <Button
                            primary
                            accent
                            dataQa="current-calls-search"
                            className={classes.button}
                            onClick={() => handleSubmit()}
                            skipPermission
                        >
                            {t('common:search')}
                        </Button>

                        

                        <ActiveCallsInfo />
                    </Grid>
                </Grid>
            </form>
            <DataGrid<SipCall>
                columns={columns}
                data={filtered?.map((v, index) => ({
                    ...v,
                    id: index.toString(),
                })) || []}
                rowCount={filtered?.length || 0}
                loading={isLoadingSipCalls}
                paginationMode={PaginationMode.Client}
                centeredRows
                narrowRows
                customRowHeight={63}
                initialPageSize={10}
                classes={{
                    tableContainer: classes.tableContainer,
                    header: classes.tableContainer,
                }}
            />
        </>
    );
};
