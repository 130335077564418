import { Colors } from "../../../../styles/Colors";
import { makeStyles } from '@material-ui/core';
import * as Yup from 'yup';
import i18n from "../../../../services/i18n";
import { PeriodInfo } from "../../../../store/types/AccountFollowMeSettings";
import {
    convertArrayOfDaysStringToDaysSelectItems,
    convertArrayOfMonthsStringToMonthSelectItems,
    convertArrayOfNumbersToRangesString,
    convertArrayOfYearsStringToYearsSelectItems,
    convertRangesStringToNumbersArray,
    getPeriods,
    getYearsPeriods
} from "../../../../utils/extensions/RingScheduleSummary";
import { CPConditionInfo } from "../../../../store/types/CallScreening";
import { TFunction } from "react-i18next";
import dayjs from "../../../../services/customDayJs";
import { checkIsWholeDay, hourTypes, TimeRange } from "../../../TimeRangePicker/TimeRangePicker.utils";
import moment from "moment";
import { isNaN } from "lodash";

export type NewIntervalDialogProps = {
    isOpen: boolean;
    cpCondition: CPConditionInfo;
    editObject?: PeriodInfo;
    clickOnClose?: () => void;
};

export type PeriodEditModel = {
    end_time: string;
    start_time: string;
    monthday_list: string;
    weekday_list: string[];
    month_list: string[];
    year_list: string[];
    ampm: boolean;
}

const invalidMonthdayFormat = i18n.t<string>('errors:callSettings.invalidMonthdayFormat');

export const newIntervalValidationSchema = Yup.object().shape({
    start_time: Yup.string()
        .notRequired(),
    end_time: Yup.string()
        .notRequired(),
    monthday_list: Yup.string()
            .notRequired()
            .test('is-valid-format', invalidMonthdayFormat, monthday_list => {
                if(!monthday_list?.length) return true;
                const parsed = convertRangesStringToNumbersArray(monthday_list || '');
                const invalidItem = parsed.find(e => isNaN(e) || !e || e < 1 || e > 31);
                return invalidItem === undefined;
            })
});

export const useStyles = makeStyles(() => ({
    modalContainer: {
        '& .MuiDialogContent-root': {
            background: Colors.SmokeBackground,
            height: 475,
            width: 576,
            padding: 0,
            margin: 0,
            overflowY: 'auto',
            maxHeight: 'calc(90vh - 122px)'
        },

        '& .MuiDialog-paperWidthSm': {
            maxWidth: 'unset',
            maxHeight: 'unset'
        },

        '& .MuiGrid-root:first-child': {
            marginTop: 0,
        },

        '& .MuiPaper-root': {
            width: 576,
        },
        
        '& .MuiFormHelperText-root': {
            right: 0,
            fontSize: '0.75rem',
            fontWeight: 400
        },
    },

    header: {
        '& .MuiTypography-h6': {
            fontWeight: 700,
            fontSize: 18,
        },
    },

    itemsContainer: {
        paddingLeft: 88,
        paddingTop: 10,
        paddingRight: 88,
        paddingBottom: 49,
        display: 'flex',
        flexDirection: 'column',

        '& p': {
            fontWeight: 700,
            fontSize: 16,
            lineHeight: '20px',
        },

        '& .MuiFormControl-root': {
            width: 406,
            height: 56
        },
    },

    form: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        padding: 0,
        margin: 0,
        overflowX: 'hidden'
    },
    
    boxRow: {
        marginTop: 24,
        display: 'flex',
        alignItems: 'flex-start',
        width: 472,

        '& .Mui-error': {
            fontWeight: 400,
        },
        
        '& > :not(:first-child)': {
            marginLeft: 24,
        },
    },

    customHoverButton: {
        backgroundColor: Colors.Secondary5,

        '&:hover': {
            backgroundColor: Colors.Secondary5
        }
    },

    helperBox: {
        height: 12,
        paddingTop: 0,
        paddingBottom: 0
    },
    
    helperText: {
        fontSize: 12,
        marginLeft: 16,
        marginTop: 8,
        color: Colors.Gray5,
    },
    
    multiSelectContainer: {
        minWidth: 'unset',
        flex: 1,

        '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input': {
            padding: '0!important',
            flexGrow: 'unset',
            minWidth: 'unset',
        }
    },

    multiSelectField: {
        minHeight: 56,

        '& .MuiAutocomplete-inputRoot': {
            height: 56,
        }
    },

    multiSelectFieldOverflowText: {
        flex: 1,
        marginLeft: 12,
        width: 326,
    },

    popperContainer: {
        width: 372
    },

    popperContainerAmPm: {
        maxWdth: 482,
        width: 'fit-content',
    }
}));

export const stringifyInterval = (
    t: TFunction<string>,
    period: PeriodInfo,
    ampm?: boolean
) => { 

    const interval = {
        wholeDay: checkIsWholeDay(parseTimeRange(period.start_time), parseTimeRange(period.end_time)),
        startTime: convertLongTimeToShort(period.start_time, ampm),
        endTime: convertLongTimeToShort(period.end_time, ampm),
        daysOfMonth: convertArrayOfNumbersToRangesString(
            period.monthday_list,
        ),
        days: convertArrayOfDaysStringToDaysSelectItems(
            period.weekday_list,
        ),
        months: convertArrayOfMonthsStringToMonthSelectItems(
            period.month_list,
        ),
        years: convertArrayOfYearsStringToYearsSelectItems(
            period.year_list,
        ),
    };
    
    let nestedResult = '';
    if (!interval.wholeDay) {
        nestedResult += t('screens:callSettings.fromTill', {
            startTime: convertLongTimeToShort(period.start_time, ampm),
            endTime: convertLongTimeToShort(period.end_time, ampm),
        });
    } else {
        nestedResult += t('screens:ringSchedule.selectWholeDay') + ' ';
    }

    if (interval.days?.length) {
        nestedResult += t('screens:callSettings.onDay', {
            value: getPeriods(interval.days, dayjs.weekdaysShort()),
        });
    }

    if (interval.daysOfMonth?.length) {
        nestedResult += `${interval.daysOfMonth} `;
    }

    if(!interval.days?.length && !interval.daysOfMonth?.length) {
        nestedResult += t('screens:callSettings.ofEveryDay');
    }

    if (!interval.months?.length || interval.months.length === 12) {
        nestedResult += t('screens:callSettings.ofEveryMonth');
    } else {
        nestedResult += t('screens:callSettings.ofMonth', {
            value: getPeriods(interval.months, dayjs.months()),
        });
    }

    if (interval.years && interval.years.length > 0) {
        nestedResult += getYearsPeriods(interval.years);
    }

    return nestedResult;
};

export const parseTimeRange = (str: string) => {
    const parts = (str || '').split(/:| /);
    const lastPart = parts[parts.length - 1]?.toUpperCase();
    const ampm = hourTypes.find(e => e === lastPart) !== undefined;
    return {
        hours: parseInt(parts[0]) + '',
        minutes: parseInt(parts[1]) + '',
        hourType: ampm ? lastPart : ''
    } as TimeRange;
}

export const toShortTimeFormat = (range: TimeRange) => {
    return `${range.hours.padStart(2, '0')}:${range.minutes.padStart(2, '0')}`
        + (range.hourType?.length ? ' ' + range.hourType : '');
};

export const toLongTimeFormatForApi = (range: TimeRange) => {
    const hours = range.hourType === hourTypes[1] 
            ? (parseInt(range.hours) + 12) + ''
            : range.hours;

    return `${hours.padStart(2, '0')}:${range.minutes.padStart(2, '0')}` + ':00';
};

export const cropSeconds = (input?: string) => {
    if(!input?.length) return input;
    const range = parseTimeRange(input);
    return toShortTimeFormat(range);
}

export const convertLongTimeToShort = (time?: string, ampm?: boolean) => {
    if(!time?.length) return time;
    const short = cropSeconds(time);
    return moment(short, 'HH:mm').format(ampm ? 'hh:mm A' : 'HH:mm');
};