import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Box, Grid} from '@material-ui/core';
import {useFormik} from 'formik';
import {useTranslation} from 'react-i18next';
import TextField from '../../TextField/TextField';
import DialogContainer, {
    DialogButton,
} from '../../AlertDialog/DialogContainer';
import {Colors} from '../../../styles/Colors';
import classNames from 'classnames';
import {RingGroupType} from '../../../store/types/RingGroup';
import Checkbox from '../../Checkbox/Checkbox';
import Radio from '../../Radio/Radio';
import SelectField from '../../SelectField/SelectField';
import {prepareInitialValues} from '../../../utils/extensions/AddExtensionToRingGroup';
import IconWithTooltip from '../../Tooltip/IconWithTooltip';
import SwitchWithLabel from '../../SwitchWithLabel/SwitchWithLabel';
import {Delete} from '@material-ui/icons';
import AlertDialog from '../../AlertDialog/AlertDialog';
import {
    AddToRingGroupDialogProps,
    AddtoRingGroupFormProps,
    addtoRingGroupFormValidationSchema,
    DispatchType,
    formInitialValues,
    useStyles,
} from './AddToRingGroupDialog.utils';
import AllowGroupPagging from "../../RingGroups/AllowGroupPagging";

const AddToRingGroupDialog: React.VFC<AddToRingGroupDialogProps> = (
    {
        isOpen,
        ringGroups = [],
        extensionRingGroups = [],
        ringGroupToEdit,
        accountId,
        toggleVisibility,
        onExclude,
        onAddNewRingGroup,
        extensionName,
    }) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const [excludeDialogVisible, setExcludeDialogVisible] = useState(false);

    const initialValues = useMemo(() => {
        if (ringGroupToEdit && accountId) {
            const assignedExtension = ringGroupToEdit.assigned_extensions?.find(
                (v) => v.account_id === accountId,
            );
            return prepareInitialValues(ringGroupToEdit, assignedExtension);
        }

        return formInitialValues;
    }, [ringGroupToEdit, accountId]);

    const onClickExclude = useCallback(() => {
        setExcludeDialogVisible(false);
        ringGroupToEdit && onExclude?.(ringGroupToEdit?.id);
        toggleVisibility?.();
    }, [toggleVisibility, ringGroupToEdit]);

    const {
        values,
        handleSubmit,
        handleChange,
        setFieldValue,
        setFieldError,
        errors,
        resetForm,
        dirty,
    } = useFormik<AddtoRingGroupFormProps>({
        initialValues,
        onSubmit: (form) => {
            onAddNewRingGroup?.(form);
            setTimeout(resetForm, 200);
        },
        validationSchema: addtoRingGroupFormValidationSchema,
        enableReinitialize: true,
        validateOnChange: false,
    });

    const isPrimaryGroupDisabled = useMemo(
        () =>
            extensionRingGroups.findIndex((w) => {
                const assignedExtension = w.assigned_extensions?.find(
                    (v) => v.account_id === accountId,
                );

                return (
                    assignedExtension?.primary_group === 'Y' &&
                    w.i_c_group !== ringGroupToEdit?.i_c_group
                );
            }) !== -1,
        [extensionRingGroups, ringGroupToEdit, accountId],
    );

    useEffect(() => {
        if (values.ringing && values.dispatchType === DispatchType.Disabled) {
            setFieldValue('dispatchType', DispatchType.PermamentlyEnabled)
        }
    }, [values.ringing]);

    return (
        <>
            <DialogContainer
                isOpen={isOpen}
                dataQa="add-to-ring-group-dialog"
                header={
                    ringGroupToEdit
                        ? t(
                            'screens:addToRingGroup.editRingGroupMemberOptions',
                            {
                                value: ringGroupToEdit.name,
                            },
                        )
                        : t('screens:addToRingGroup.addToRingGroup')
                }
                headerClass={classes.header}
                className={classNames(
                    classes.modalContainer,
                    !!ringGroupToEdit && classes.modalContainerForEdit,
                )}
                dialogActionsButtons={[
                    <DialogButton
                        key="cancel"
                        label={t('common:cancel')}
                        onClick={() => {
                            toggleVisibility?.();
                            setTimeout(resetForm, 200);
                        }}
                        dataQa={'cancel-ring-group-button'}
                    />,
                    <DialogButton
                        key="save"
                        label={t('common:save')}
                        onClick={handleSubmit}
                        dataQa={'add-ring-group-save-button'}
                        disabled={!dirty}
                        primary
                    />,
                ]}
                dialogLeftSideActionsButtons={
                    ringGroupToEdit
                        ? [
                            <DialogButton
                                key="remove"
                                label={t(
                                    'screens:extensions.excludeFromGroup',
                                )}
                                onClick={() => setExcludeDialogVisible(true)}
                                dataQa={'cancel-ring-group-button'}
                                removeAccent
                                icon={
                                    <Delete
                                        htmlColor={Colors.Gray5}
                                        style={{marginRight: 5}}
                                    />
                                }
                            />,
                        ]
                        : undefined
                }
            >
                <form
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    data-testid="add-to-ring-group-form"
                >
                    <Grid item className={classes.itemsContainer}>
                        {!ringGroupToEdit && (
                            <SelectField
                                id="groupId"
                                label={t('screens:addToRingGroup.groupName')}
                                items={ringGroups}
                                value={
                                    ringGroups.find(
                                        (v) => v.id === values.groupId,
                                    ) || null
                                }
                                dataQa="group-name-input"
                                dataTestId="group-name-input"
                                onChange={(_, value) => {
                                    setFieldValue('groupId', value.id);
                                }}
                                getOptionLabel={(v: RingGroupType) =>
                                    v.name || ''
                                }
                                getOptionSelected={(v: RingGroupType) =>
                                    v.id === values.groupId
                                }
                                required
                                className={classes.groupSelect}
                                helperText={
                                    errors.groupId
                                        ? t('errors:extensions.groupName')
                                        : ''
                                }
                                setFieldValue={setFieldValue}
                                setFieldError={setFieldError}
                                disableClearable
                            />
                        )}
                        <Box flexDirection="column" display="flex">
                            <Grid>
                                <Box display="flex" alignItems="center">
                                    <Checkbox
                                        dataQa="ignore-follow-me-voicemail"
                                        checked={values.ignoreFollowMeVoiceMail}
                                        onChange={(e) =>
                                            setFieldValue(
                                                'ignoreFollowMeVoiceMail',
                                                e.target.checked,
                                                false,
                                            )
                                        }
                                        label={t(
                                            'screens:addToRingGroup.ignoreFollowMeVoicemail',
                                        )}
                                    />
                                    <IconWithTooltip
                                        dataQa="ignoreFollowMeVoicemail-tooltip"
                                        tooltipText={t(
                                            'tooltips:ringGroups.ignoreFollowMeVoicemail',
                                        )}
                                    />
                                </Box>
                            </Grid>
                            <Grid>
                                <Box display="flex" alignItems="center">
                                    <Checkbox
                                        dataQa="set-group-as-primary"
                                        checked={values.setAsPrimary}
                                        onChange={(e) =>
                                            setFieldValue(
                                                'setAsPrimary',
                                                e.target.checked,
                                                false,
                                            )
                                        }
                                        label={t(
                                            'screens:addToRingGroup.setGroupAsPrimary',
                                        )}
                                        disabled={isPrimaryGroupDisabled}
                                    />

                                    <IconWithTooltip
                                        dataQa="primaryGroup-tooltip"
                                        tooltipText={t(
                                            'tooltips:ringGroups.primaryGroup',
                                        )}
                                    />
                                </Box>
                            </Grid>
                            <Grid>
                                <AllowGroupPagging
                                    readOnly={false}
                                    type={'extensions'}
                                    allowGroupPaging={values.allowGroupPaging}
                                    onChange={(e) =>

                                        setFieldValue(
                                            'allowGroupPaging',
                                            e.target.checked,
                                            false,
                                        )}

                                />
                            </Grid>
                            <Grid>
                                <Box
                                    className={classes.marginBottom}
                                    display="flex"
                                    alignItems="center"
                                >
                                    <Checkbox
                                        dataQa="supervisor"
                                        checked={values.supervisor}
                                        onChange={(e) =>
                                            setFieldValue(
                                                'supervisor',
                                                e.target.checked,
                                            )
                                        }
                                        label={t(
                                            'screens:addToRingGroup.supervisor',
                                        )}
                                    />

                                    <IconWithTooltip
                                        dataQa="supervisor-tooltip"
                                        tooltipText={t(
                                            'tooltips:ringGroups.supervisor',
                                        )}
                                    />
                                </Box>
                            </Grid>
                        </Box>
                    </Grid>

                    <SwitchWithLabel
                        label={t('screens:ringGroups.ringing')}
                        setValue={setFieldValue}
                        value={values.ringing}
                        field={'ringing'}
                        id={'ringing'}
                        className={classes.ringing}
                        icon={
                            <IconWithTooltip
                                dataQa="ringing-tooltip"
                                tooltipText={t('tooltips:ringGroups.ringing')}
                            />
                        }
                    />

                    {values.ringing && (
                        <Box display="flex">
                            <Box
                                className={classNames(
                                    classes.itemsContainer,
                                    classes.radioContainer,
                                    values.dispatchType ===
                                    DispatchType.PermamentlyEnabled &&
                                    classes.selectedRadio,
                                )}
                                flex={1}
                            >
                                <Grid>
                                    <Grid>
                                        <Radio
                                            dataQa="dispatch-type-permamently-enabled"
                                            checked={
                                                values.dispatchType ===
                                                DispatchType.PermamentlyEnabled
                                            }
                                            name="dispatchType"
                                            value={values.dispatchType}
                                            onChange={() =>
                                                setFieldValue(
                                                    'dispatchType',
                                                    DispatchType.PermamentlyEnabled,
                                                    false,
                                                )
                                            }
                                            label={t(
                                                'screens:addToRingGroup.permamently',
                                            )}
                                        />
                                    </Grid>
                                    <p
                                        className={classes.descriptionRadio}
                                        data-qa="description-radio"
                                    >
                                        {t(
                                            'screens:addToRingGroup.permamentlyEnabledDescription',
                                        )}
                                    </p>
                                </Grid>
                            </Box>

                            <Box
                                className={classNames(
                                    classes.itemsContainer,
                                    classes.radioContainer,
                                    values.dispatchType ===
                                    DispatchType.Custom &&
                                    classes.selectedRadio,
                                )}
                                flex={1}
                            >
                                <Box>
                                    <Grid>
                                        <Radio
                                            dataQa="dispatch-type-custom"
                                            checked={
                                                values.dispatchType ===
                                                DispatchType.Custom
                                            }
                                            name="dispatchType"
                                            value={values.dispatchType}
                                            onChange={() =>
                                                setFieldValue(
                                                    'dispatchType',
                                                    DispatchType.Custom,
                                                    false,
                                                )
                                            }
                                            label={t(
                                                'screens:addToRingGroup.custom',
                                            )}
                                        />
                                    </Grid>
                                </Box>

                                <TextField
                                    id="customWaitsFor"
                                    label={t('screens:addToRingGroup.waitsFor')}
                                    onChange={handleChange}
                                    value={values.customWaitsFor?.toString()}
                                    type="number"
                                    iconPosition="end"
                                    className={classNames(
                                        classes.numberInput,
                                        classes.inputMargin,
                                    )}
                                    dataQa="custom-wait-for-input"
                                    disabled={values.dispatchType !== 1}
                                    inputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 999,
                                        },
                                        pattern: '[0-9]*',
                                        startAdornment: (
                                            <span className={classes.minText}>
                                                {t('screens:extensions.sec')}
                                            </span>
                                        ),
                                    }}
                                    helperText={errors.customWaitsFor}
                                />

                                <TextField
                                    id="customThenRingsFor"
                                    label={t(
                                        'screens:addToRingGroup.thenRingFor',
                                    )}
                                    onChange={handleChange}
                                    value={values.customThenRingsFor?.toString()}
                                    type="number"
                                    className={classNames(
                                        classes.numberInput,
                                        classes.inputMargin,
                                    )}
                                    dataQa="custom-then-rings-for-input"
                                    disabled={values.dispatchType !== 1}
                                    inputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 999,
                                        },
                                        pattern: '[0-9]*',
                                        startAdornment: (
                                            <span className={classes.minText}>
                                                {t('screens:extensions.sec')}
                                            </span>
                                        ),
                                    }}
                                    helperText={errors.customThenRingsFor}
                                />
                            </Box>
                        </Box>
                    )}
                </form>
            </DialogContainer>

            <AlertDialog
                isOpen={excludeDialogVisible}
                description={t(
                    'screens:extensions.excludeExtensionFromRingGroup',
                    {
                        extensionName: extensionName,
                        ringGroupName: ringGroupToEdit?.name,
                    },
                )}
                contentClass="alert-content"
                className={classes.confirmDialogContainer}
                hideHeader={true}
                dialogActionsButtons={[
                    <DialogButton
                        key="cancel"
                        label={t('common:cancel')}
                        onClick={() => setExcludeDialogVisible(false)}
                    />,
                    <DialogButton
                        key="exlude"
                        label={t('screens:ringGroups.exclude')}
                        onClick={onClickExclude}
                        dataQa="confirm-exclude-ring-group"
                    />,
                ]}
                dataQa="remove-ringgroup-modal"
                dataTestId="remove-ringgroup-modal"
            />
        </>
    );
};

export default AddToRingGroupDialog;
