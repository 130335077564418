import { useTabStyles } from '../../views/Extensions/ExtensionDetails/utils';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../store/types';
import Loader from '../Loader/Loader';
import { Formik } from 'formik';
import { useEditExtensionCallForwardingFormData } from '../../hooks/useEditExtensionCallForwardingForm';
import CallForwardingForm from '../Forms/Extensions/CallForwarding/CallForwardingForm';
import {
    ExtensionCallForwardingFormType,
    extensionCallForwardingFormValidationSchema,
} from '../Forms/Extensions/CallForwarding/CallForwardingForm.utils';
import { TabInterface } from './Tabs.utils';
import { ACCOUNT_FOLLOW_ME_ACCESS_DENIED, ACCOUNT_PERMITTED_SIP_PROXIES_ACCESS_DENIED } from '../../store/sagas/autoAttendants/saga';
import AccessDenied from '../PermissionProvider/AccessDenied';

const CallForwardingTab: React.VFC<TabInterface> = ({
    handleSubmitForm,
    handleInitDataChange,
    handleDirtyChange,
    handleSetSubmitFunc,
    handleSetIsValidFunc,
}) => {
    const classes = useTabStyles();

    const { initFormData, data } = useEditExtensionCallForwardingFormData();

    const isDataLoading = useSelector(
        (state: ReduxState) =>
            state.extensions.extensionDetailsTabs?.callForwardingTab
                ?.isLoading || false,
    );
    
    const apiError = useSelector(
        (state: ReduxState) => state.extensions?.apiErrorFollowMeState
            || state.extensions?.sipInfoErrors,
    );
    
    useEffect(() => {
        if (!isDataLoading && initFormData) {
            handleInitDataChange?.(initFormData);
            handleSubmitForm?.(initFormData);
        }
    }, [isDataLoading, initFormData]);

    if(apiError?.faultcode == ACCOUNT_FOLLOW_ME_ACCESS_DENIED ||
        apiError?.faultcode == ACCOUNT_PERMITTED_SIP_PROXIES_ACCESS_DENIED) {
        return <AccessDenied
            subPageMode
        />;
    }

    return isDataLoading ? (
        <div className={classes.loader}>
            <Loader dataQa="incoming-list-loader" />
        </div>
    ) : (
        <Formik<ExtensionCallForwardingFormType>
            initialValues={initFormData}
            validationSchema={extensionCallForwardingFormValidationSchema}
            onSubmit={handleSubmitForm!}
            enableReinitialize
            validateOnChange={false}
        >
            <CallForwardingForm
                handleDirtyChange={handleDirtyChange}
                handleSetSubmitFunc={handleSetSubmitFunc}
                handleSetIsValidFunc={handleSetIsValidFunc}
                permittedSipProxies={data.permittedSipProxies}
            />
        </Formik>
    );
};

export default CallForwardingTab;
