import React, { useEffect } from 'react';
import { useTabStyles } from '../../views/Extensions/ExtensionDetails/utils';
import Loader from '../Loader/Loader';
import { Formik } from 'formik';
import CallScreeningForm from '../Forms/Extensions/CallScreening/CallScreeningForm';
import { useCallScreeningFormData } from '../../hooks/useCallScreeningFormData';
import { CallScreeningFormType } from '../Forms/Extensions/CallScreening/CallScreeningForm.utils';
import { useSelector } from 'react-redux';
import { APIErrorInterface, ReduxState } from '../../store/types';
import { TabInterface } from './Tabs.utils';
import { ACCOUNT_CALL_PROCESSING_RULES_ACCESS_DENIED, ACCOUNT_CALL_PROCESSING_LIST_ACCESS_DENIED } from '../../store/sagas/autoAttendants/saga';
import AccessDenied from '../PermissionProvider/AccessDenied';

interface CallScreeningTabInterface extends TabInterface {
    showPBX?: boolean;
    forceTooltip?: string;
    extensionNumber?: string;
}

const CallScreeningTab: React.FC<CallScreeningTabInterface> = ({
    handleInitDataChange,
    handleSubmitForm,
    handleDirtyChange,
    handleSetSubmitFunc,
    handleSetIsValidFunc,
    showPBX = true,
    forceTooltip,
    extensionNumber,
}) => {
    const classes = useTabStyles();

    const isDataLoading = useSelector(
        (state: ReduxState) =>
            state.extensions.extensionDetailsTabs?.callScreeningTab
                ?.isLoading || false,
    );

    const { initFormData, data } = useCallScreeningFormData();
    const apiError =  useSelector<ReduxState, APIErrorInterface | undefined>(
        (state: ReduxState) => state.extensions?.fetchApiError
    );
    
    useEffect(() => {
        if (!isDataLoading && initFormData) {
            handleInitDataChange?.(initFormData);
            handleSubmitForm?.(initFormData);
        }
    }, [isDataLoading]);
    
    if(apiError?.faultcode === ACCOUNT_CALL_PROCESSING_RULES_ACCESS_DENIED ||
        apiError?.faultcode === ACCOUNT_CALL_PROCESSING_LIST_ACCESS_DENIED)  {
        return (
            <AccessDenied subPageMode />
        );
    }

    return isDataLoading ? (
        <div className={classes.loader}>
            <Loader dataQa="call-screening-loader" />
        </div>
    ) : (
        <Formik<CallScreeningFormType>
            initialValues={initFormData}
            onSubmit={(form) => handleSubmitForm?.(form)}
            enableReinitialize={true}
            validateOnChange={false}
        >
            <CallScreeningForm
                service={data.serviceValue}
                mode={data.mode}
                handleSubmitForm={handleSubmitForm}
                handleDirtyChange={handleDirtyChange}
                handleSetSubmitFunc={handleSetSubmitFunc}
                handleSetIsValidFunc={handleSetIsValidFunc}
                showPBX={showPBX}
                forceTooltip={forceTooltip}
                extensionNumber={extensionNumber}
            />
        </Formik>
    );
};

export default CallScreeningTab;
