import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { Colors, primaryColor } from '../../styles/Colors';
import classNames from 'classnames';

type LoaderProps = {
    dataQa: string;
    size?: number | string;
    absolutePosition?: boolean;
    dataTestId?: string;
    text?: string;
};

const useStyles = makeStyles(() => ({
    loader: {
        display: 'flex',
        justifyContent: 'center',
        zIndex: 1350,
        flexDirection: 'column',

        '& .MuiCircularProgress-svg': {
            color: primaryColor,
        },
    },
    absolute: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.WhiteWithOpacity,
    },
}));

const Loader: React.VFC<LoaderProps> = ({
    dataQa,
    size,
    absolutePosition,
    dataTestId,
    text
}) => {
    const classes = useStyles();

    return (
        <div
            className={classNames(
                classes.loader,
                absolutePosition && classes.absolute,
            )}
            data-testid={dataTestId}
        >
            <CircularProgress
                data-qa={dataQa}
                size={size}
                data-testid="loader"
            />
            {text?.length && (
                <span>{text}</span>
            )}
        </div>
    );
};

export default Loader;
