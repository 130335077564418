import React, { useEffect, useState } from "react";
import FormLabel from "../../../FormLabel/FormLabel";
import {useTranslation} from "react-i18next";
import CustomButton from "../../../Button/Button";
import {Add} from "@material-ui/icons";
import {Colors} from "../../../../styles/Colors";
import {useDispatch, useSelector} from "react-redux";
import {ReduxState} from "../../../../store/types";
import {GetCallProcessingPolicyListEntity} from "../../../../store/actions/extensions/payloads";
import { CallScreeningFormType, useStyles } from "./PoliciesForm.utils";
import PolicyOption from "../../../PolicyOption/PolicyOption";
import NewPolicyDialog from "./NewPolicyDialog";
import AlertDialog from "../../../AlertDialog/AlertDialog";
import { DialogButton } from "../../../AlertDialog/DialogContainer";
import { actions } from "../../../../store";
import { DeletePolicyFaultCodes } from "../../../../store/actions/callSettings/payloads";
import { showErrorToast } from "../../../../utils/showErrorToast";
import { CPRuleInfo } from "../../../../store/types/CallScreening";
import NewRuleDialog from "./NewRuleDialog";
import { useFormikContext } from "formik";

enum DialogType {
    policy = 'policy',
    rule = 'rule'
};

export type PoliciesFormProps = {};

const PoliciesForm: React.VFC<PoliciesFormProps> = () => {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const policies = useSelector<ReduxState, GetCallProcessingPolicyListEntity[] | undefined>((state) => 
        state.extensions.callProcessingPolicyList);
        
    const deletePolicyApiError = useSelector((state: ReduxState) => 
        state.extensions.deletePolicyApiError);
        
    const [deleteDialog, setDeleteDialog] = useState<{
        isOpen: boolean;
        type?: DialogType;
        name?: string;
        id?: number;
        policy?: GetCallProcessingPolicyListEntity;
    }>({
        isOpen: false,
    });
    
    const [showAddNewPolicyDialog, setShowAddNewPolicyDialog] = useState<{
        isOpen: boolean;
        editObject?: GetCallProcessingPolicyListEntity;
    }>({
        isOpen: false,
    });
    
    const [showAddNewRuleDialog, setShowAddNewRuleDialog] = useState<{
        isOpen: boolean;
        policy?: GetCallProcessingPolicyListEntity;
        editObject?: CPRuleInfo;
    }>({
        isOpen: false
    });

    const deleteDialogQuestion = () => {
        switch(deleteDialog.type) {
            case DialogType.policy:
                return t('screens:callSettings.deletePolicy', {
                    name: deleteDialog.name || '',
                });

            case DialogType.rule:
                return t('screens:callSettings.deleteRule');
        }
        return '';
    };
    
    const closeDeleteDialog = () => {
        setDeleteDialog({
            isOpen: false,
            type: undefined,
            name: undefined,
            id: undefined,
            policy: undefined
        });
    };

    const handleDeleteAction = () => {
        switch(deleteDialog.type) {
            case DialogType.policy:
                dispatch(actions.deletePolicy.request({
                    i_cp_policy: deleteDialog.id || 0,
                    callback: () => {
                        closeDeleteDialog();
                    }
                }));
            break;

            case DialogType.rule:
                const policy = deleteDialog.policy;
                const ruleToDelete = policy?.rule_list.find(e => e.i_cpp_rule === deleteDialog.id);
                closeDeleteDialog();
                if(ruleToDelete && policy) {
                    const indx = policy.rule_list.indexOf(ruleToDelete);
                    policy?.rule_list?.splice(indx, 1);
                    dispatch(actions.editPolicy.request({
                        object: policy,
                        successToastText: t('screens:callSettings.editPolicySuccess'),
                        callback: () => {
                            setFieldValue('policies', []);
                        }
                    }));
                }
            break;
        }
    };
    
    useEffect(() => {
        if(deleteDialog.isOpen && deletePolicyApiError) {
            switch(deletePolicyApiError.faultcode) {
                case DeletePolicyFaultCodes.PolicyIsInUse:
                    showErrorToast(t<string>('errors:callSettings.cantDeletePolicyIsInUse'));
                    break;
                    
                default:
                    if(deletePolicyApiError?.faultstring) {
                        showErrorToast(deletePolicyApiError.faultstring);
                    }
                    break;
            }
            closeDeleteDialog();
            dispatch(actions.deletePolicyApiErrorStateUpdate(undefined));
        }
    }, [deleteDialog, deletePolicyApiError]);

    const getNewRuleOrderNumber = (policy?: GetCallProcessingPolicyListEntity) => {
        const ordered = policy?.rule_list;
        return ordered?.length ? (ordered[ordered.length - 1].order_number || 0) + 1 : 1;
    };

    const { setFieldValue } = useFormikContext<CallScreeningFormType>();

    return (
        <>
            <div className={classes.addPolicyContainer}>
                <FormLabel
                    label={t('screens:callSettings.policiesLabel')}
                    tooltipText={t('tooltips:callSettings.policies')}
                    dataQa={'outbound'}
                />
                <CustomButton
                    primary
                    accent
                    dataQa="add-policy-button"
                    onClick={() => {
                        setShowAddNewPolicyDialog({
                            isOpen: true,
                            editObject: undefined
                        });
                    }}
                    className={classes.addPolicyButton}
                >
                    <Add
                        htmlColor={Colors.White}
                        style={{height: 22, width: 22, marginRight: 10}}
                    />
                    {t('screens:callSettings.addPolicies')}
                </CustomButton>
            </div>
            <div className={classes.policiesListContainer}>
                {policies?.map((policy, index) => (
                    <PolicyOption
                        key={policy.i_cp_policy || index}
                        policy={policy}
                        isTheLastOne={index === policies.length - 1}
                        onDelete={() => {
                            setDeleteDialog({
                                isOpen: true,
                                type: DialogType.policy,
                                name: policy.name,
                                id: policy.i_cp_policy
                            });
                        }}
                        onEdit={() => {
                            setShowAddNewPolicyDialog({
                                isOpen: true,
                                editObject: policy
                            });
                        }}
                        onAddRule={() => {
                            setShowAddNewRuleDialog({
                                isOpen: true,
                                editObject: undefined,
                                policy: policy
                            });
                        }}
                        onEditRule={(rule) => {
                            setShowAddNewRuleDialog({
                                isOpen: true,
                                editObject: rule,
                                policy: policy
                            });
                        }}
                        onDeleteRule={(rule) => {
                            setDeleteDialog({
                                isOpen: true,
                                type: DialogType.rule,
                                name: (rule.i_cpp_rule || 0) + '',
                                id: rule.i_cpp_rule || 0,
                                policy: policy
                            });
                        }}
                        setFieldValue={(field) => {
                            setFieldValue(`policies.${index}`, field ? policy : undefined);
                        }}
                    />
                ))}
            </div>
            <NewPolicyDialog
                isOpen={showAddNewPolicyDialog.isOpen}
                clickOnClose={() => {
                    setShowAddNewPolicyDialog({
                        isOpen: false,
                        editObject: undefined
                    });
                }}
                editObject={showAddNewPolicyDialog.editObject}
            />
            <NewRuleDialog
                isOpen={showAddNewRuleDialog.isOpen}
                clickOnClose={(changesApplied) => {
                    setShowAddNewRuleDialog({
                        isOpen: false,
                    });
                    if(changesApplied) {
                        setFieldValue('policies', []);
                    }
                    setTimeout(setShowAddNewRuleDialog, 100, {
                        isOpen: false,
                        editObject: undefined,
                        policy: undefined
                    });
                }}
                editObject={showAddNewRuleDialog.editObject}
                order_number={showAddNewRuleDialog.editObject?.order_number || getNewRuleOrderNumber(showAddNewRuleDialog.policy)}
                i_cp_policy={showAddNewRuleDialog.policy?.i_cp_policy || 0}
            />
            <AlertDialog
                className={classes.deleteDialog}
                contentClass={classes.contentDialog}
                isOpen={deleteDialog.isOpen}
                dataQa={'delete-item-dialog'}
                hideHeader
                description={<>{deleteDialogQuestion()}</>}
                dialogActionsButtons={[
                    <DialogButton
                        key="cancel"
                        label={t('common:cancel')}
                        onClick={closeDeleteDialog}
                    />,
                    <DialogButton
                        key="delete"
                        label={t('common:delete')}
                            onClick={handleDeleteAction}
                    />,
                ]}
            />
        </>
    )
}

export default PoliciesForm;