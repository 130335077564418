import React, {useEffect, useMemo, useState} from 'react';
import {Grid} from '@material-ui/core';
import {useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import Radio from '../Radio/Radio';
import DragDropDataTable from '../DataTable/DragDropDataTable';
import ExistingIntervalRow from './ExistingIntervalRow';
import {CallForwardingRule} from '../Forms/Extensions/CallForwarding/CallForwardingForm.utils';
import CreationButton from '../CreationButton/CreationButton';
import {useSelector} from 'react-redux';
import {ReduxState} from '../../store/types';
import AlertDialog from '../AlertDialog/AlertDialog';
import {DialogButton} from '../AlertDialog/DialogContainer';
import {generateColumns, IntervalStatus, IntervalType, useStyles} from "./IntervalSelect.utils";

type IntervalSelectProps = {
    withoutYears?: boolean;
    defaultIntervalType?: IntervalType;
};

const IntervalSelect: React.VFC<IntervalSelectProps> = (
    {
        withoutYears,
        defaultIntervalType = 'old',
    }) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const [
        intervalToDeleteType,
        setIntervalToDeleteType,
    ] = useState<IntervalType>(defaultIntervalType);

    useEffect(() => {
        setIntervalToDeleteType(defaultIntervalType);
    }, [defaultIntervalType]);

    const [intervalToDeleteIndex, setIntervalToDeleteIndex] = useState<number | undefined>(undefined);

    const outTimeFormat = useSelector<ReduxState, string | undefined>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info?.out_time_format,
    );

    const confirmDelete = (indexToRemove?: number, type?: IntervalType) => {
        if (indexToRemove !== undefined) {
            setIntervalToDeleteIndex(indexToRemove);
            setIntervalToDeleteType(type || 'old');
        }
    };

    const deleteOldInterval = () => {
        if (intervalToDeleteIndex !== undefined) {
            setFieldValue(
                'intervals.oldIntervals',
                values.intervals?.oldIntervals.filter(
                    (_, index) => index !== intervalToDeleteIndex,
                ),
                false,
            );
            setIntervalToDeleteIndex(undefined);
        }
    };

    const deleteNewInterval = () => {
        if (intervalToDeleteIndex !== undefined) {
            setFieldValue(
                'intervals.newIntervals',
                values.intervals?.newIntervals.filter(
                    (_, index) => index !== intervalToDeleteIndex,
                ),
                false,
            );
            setIntervalToDeleteIndex(undefined);
        }
    };

    const addInterval = () => {
        setFieldValue(
            'intervals.newIntervals',
            [
                ...(values.intervals?.newIntervals || []),
                {
                    startTime: '00:00',
                    endTime: '23:59',
                    daysOfMonth: '',
                    days: [],
                    months: [],
                    years: [],
                    wholeDay: false,
                    type: 'new',
                },
            ],
            false,
        );
    };

    const {
        values,
        setFieldValue,
        errors,
        handleChange,
        setFieldError,
        initialValues
    } = useFormikContext<CallForwardingRule>();

    const columns = useMemo(
        () =>
            generateColumns(
                t,
                classes,
                setFieldValue,
                confirmDelete,
                errors,
                handleChange,
                setFieldError,
                withoutYears,
                outTimeFormat?.includes('AM'),
                intervalToDeleteType,
            ),
        [
            errors,
            values.intervals?.newIntervals,
            values.intervals?.oldIntervals,
            withoutYears,
            outTimeFormat,
            intervalToDeleteType,
            values.intervals?.activity
        ],
    );

    return (
        <div>
            <Grid item className={classes.itemsContainer}>
                <p className={classes.activeHeader}>
                    {t('screens:addToRingGroup.active')}
                </p>

                <Grid>
                    <Radio
                        dataQa="status-always"
                        checked={
                            values.intervals?.activity === IntervalStatus.Always
                        }
                        name="activity"
                        value={values.intervals?.activity}
                        onChange={() => {
                            setFieldValue('intervals', {
                                activity: IntervalStatus.Always,
                                oldIntervals: [],
                                newIntervals:[]
                            })
                        }}
                        label={t('screens:ringSchedule.always')}
                    />

                    <Radio
                        dataQa="status-following-intervals"
                        checked={
                            values.intervals?.activity ===
                            IntervalStatus.OnlyFollowingTimeInterval
                        }
                        name="activity"
                        value={values.intervals?.activity}
                        onChange={() => {
                                setFieldValue('intervals', {
                                    activity: IntervalStatus.OnlyFollowingTimeInterval,
                                    oldIntervals: initialValues.intervals?.oldIntervals,
                                    newIntervals:[]
                                })
                            }
                        }
                        label={t('screens:ringSchedule.onlyFollowingTime')}
                        className={classes.secondRadio}
                    />
                </Grid>

                {values.intervals?.activity ===
                    IntervalStatus.OnlyFollowingTimeInterval && (
                    <Grid>
                        {values.intervals?.oldIntervals?.map((o, index) => (
                            <ExistingIntervalRow
                                interval={o}
                                key={index}
                                onDelete={() =>
                                    confirmDelete(index, 'old')
                                }
                            />
                        ))}
                    </Grid>
                )}

                {values.intervals?.activity ===
                    IntervalStatus.OnlyFollowingTimeInterval && (
                        <DragDropDataTable
                            className={classes.table}
                            classes={{pagination: classes.pagination}}
                            rows={values.intervals?.newIntervals}
                            columns={columns}
                            rowCount={values.intervals?.newIntervals?.length}
                            dragEnable={false}
                            hidePagination
                        />
                    )}
            </Grid>

            {values.intervals?.activity ===
                IntervalStatus.OnlyFollowingTimeInterval && (
                    <CreationButton
                        title={t('screens:ringSchedule.addInterval')}
                        onClick={addInterval}
                        className={classes.addInterval}
                    />
                )}

            <AlertDialog
                isOpen={intervalToDeleteIndex !== undefined}
                description={t('screens:extensions.deleteTimeInterval')}
                contentClass="alert-content"
                className={classes.removeDialogContainer}
                hideHeader={true}
                dialogActionsButtons={[
                    <DialogButton
                        key="cancel"
                        label={t('common:cancel')}
                        onClick={() => setIntervalToDeleteIndex(undefined)}
                    />,
                    <DialogButton
                        key="exlude"
                        label={t('common:delete')}
                        onClick={
                            intervalToDeleteType === 'new'
                                ? deleteNewInterval
                                : deleteOldInterval
                        }
                    />,
                ]}
                dataQa="remove-interval-modal"
                dataTestId="remove-interval-modal"
            />
        </div>
    );
};

export default IntervalSelect;
