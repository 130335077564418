import { InputBaseComponentProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../styles/Colors';
import _ from 'lodash';
import i18n from 'i18next';

export const useStyles = makeStyles(() => ({
    textField: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,

        '& .MuiInputBase-root': {
            minHeight: 56,
        },

        '& .MuiInput-underline::before': {
            borderColor: Colors.Border,
        },
        '& .MuiFormControl-root': {
            flex: 1,
            backgroundColor: Colors.White,
        },
        '& .MuiInputBase-input': {
            color: Colors.Text,
            borderColor: Colors.Primary,
            padding: '25px 10px 6px 10px',
            fontSize: 16,
            '&::placeholder': {
                color: Colors.Gray3,
            },
        },
        '& .Mui-focused': {
            color: Colors.Primary,
            marginLeft: 0,
        },
        '& .MuiFormLabel-root': {
            marginLeft: 10,
        },

        '& .MuiInputLabel-shrink': {
            transform: 'translate(0, 13px) scale(0.75)',
        },

        '& label + .MuiInput-formControl': {
            marginTop: 0,
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: Colors.Primary,
            },
        },
        '& .Mui-disabled:hover:before': {
            borderBottomColor: Colors.Link,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: Colors.Primary,
        },
        '& .Mui-disabled:before': {
            borderBottom: `1px solid ${Colors.Gray}`,
        },
    },
    iconButton: {
        top: 6,
        right: 6,
        position: 'absolute',
    },
    popperContainer: {
        boxShadow: '0px 2px 4px rgba(0,0,0,0.25)',
        backgroundColor: Colors.White,
        padding: '20px 17px 8px 17px',
    },
    popperRow: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 15,
    },
    selectContainer: {
        minWidth: 124,
        marginLeft: 24,
        zIndex: 9999,
    },
    timeLabel: {
        width: 40,
        minWidth: 40,
    },
    popupIndicator: {
        '& .MuiAutocomplete-listbox': {
            '& :nth-child(n)': {
                visibility: 'hidden',
                height: 0,
                minHeight: 0,
                padding: 0,
            },
            '& :nth-child(5n+1)': {
                visibility: 'visible',
                height: 40,
                minHeight: 40,
                padding: '6px 16px',
            },
        },
    },
    helperText: {
        color: Colors.Error,
        fontSize: 12,
        margin: '0 15px',
        paddingTop: 3,
        lineHeight: 'unset',
    },
    requiredSign: {
        color: Colors.Error,
    },
    error: {
        '& .MuiFormLabel-root': {
            color: Colors.Error,
        },

        '& .MuiInput-underline::before': {
            borderBottomColor: `${Colors.Error}!important`,
            borderBottomWidth: 2,
        },
    },
    wholeDayCheckbox: {
        marginLeft: 52,
    },
    testValue: {
        visibility: 'hidden',
        height: 0,
        width: 0,
    },
}));

export type TimeRangePickerProps = {
    mask?: React.ElementType<InputBaseComponentProps>;
    name?: string;
    label?: string;
    maskPlaceholder?: string;
    id?: string;
    value?: any;
    onChange?: (v: string) => void;
    className?: string;
    placeholder?: string;
    InputProps?: any;
    inputProps?: any;
    InputLabelProps?: any;
    ampm?: boolean;
    defaultValue?: string;
    required?: boolean;
    helperText?: string;
    setFieldError?: (name: string, value: any) => void;
    customClasses?: {
        popperContainer?: string;
    };
};

export type TimeRange = {
    hours: string;
    minutes: string;
    hourType: string;
};

export const default12TimeFrom: TimeRange = {
    hours: '8',
    minutes: '0',
    hourType: 'AM',
};

export const default12TimeTo: TimeRange = {
    hours: '4',
    minutes: '0',
    hourType: 'PM',
};

export const default24TimeFrom: TimeRange = {
    hours: '8',
    minutes: '0',
    hourType: '',
};

export const default24TimeTo: TimeRange = {
    hours: '16',
    minutes: '0',
    hourType: '',
};

export const defaultMin12TimeFrom: TimeRange = {
    hours: '0',
    minutes: '0',
    hourType: 'AM',
};

export const defaultMax12TimeTo: TimeRange = {
    hours: '11',
    minutes: '59',
    hourType: 'PM',
};

export const defaultMin24TimeFrom: TimeRange = {
    hours: '0',
    minutes: '0',
    hourType: '',
};

export const defaultMax24TimeTo: TimeRange = {
    hours: '23',
    minutes: '59',
    hourType: '',
};

export const default12Input = '00:00 AM - 11:59 PM';
export const default24Input = '00:00 - 23:59';

export type TimeMatrix = {
    hours: [number, number];
    minutes: [number, number];
    hourType: [number, number];
};

export const time12Matrix: { [key in string]: TimeMatrix } = {
    timeFrom: {
        hours: [0, 2],
        minutes: [3, 5],
        hourType: [6, 8],
    },
    timeTo: {
        hours: [11, 13],
        minutes: [14, 16],
        hourType: [17, 19],
    },
};

export const time24Matrix: { [key in string]: TimeMatrix } = {
    timeFrom: {
        hours: [0, 2],
        minutes: [3, 5],
        hourType: [0, 0],
    },
    timeTo: {
        hours: [8, 10],
        minutes: [11, 13],
        hourType: [0, 0],
    },
};

export const wholeDayMask = [...i18n.t<string>('screens:ringSchedule.selectWholeDay')];
export const hour12Mask = [
    /[0-1W]/,
    /[0-9]/,
    ':',
    /[0-5]/,
    /[0-9]/,
    ' ',
    /[AP]/,
    /[M]/,
    ' ',
    '-',
    ' ',
    /[0-1]/,
    /[0-9]/,
    ':',
    /[0-5]/,
    /[0-9]/,
    ' ',
    /[AP]/,
    /[M]/,
];

export const hour24Mask = [
    /[0-2W]/,
    /[0-9]/,
    ':',
    /[0-5]/,
    /[0-9]/,
    ' ',
    '-',
    ' ',
    /[0-2]/,
    /[0-9]/,
    ':',
    /[0-5]/,
    /[0-9]/,
];

export const hour12Placeholder = '__:__ __ - __:__ __';
export const hour24Placeholder = '__:__ - __:__';

const getSmallUnit = (v: string) => {
    return v[0] === '0' ? v[1] : v;
};

const checkValidity = (v: string) => {
    return v.includes('_') ? '00' : v;
};

const getFullUnit = (v: string) => {
    return v.length < 2 ? `0${v}` : v;
};

export const hours12 = Array.from(Array(13).keys()).map((v) => v.toString());
export const hours24 = Array.from(Array(24).keys()).map((v) => v.toString());
export const minutes = Array.from(Array(60).keys()).map((v) => v.toString());
export const hourTypes = ['AM', 'PM'];
export const WHOLE_DAY_VALUE = i18n.t<string>('screens:ringSchedule.selectWholeDay');

export const checkIsWholeDay = (
    timeFrom: TimeRange,
    timeTo: TimeRange,
    ampm?: boolean,
) => {
    return (
        _.isEqual(
            timeFrom,
            ampm ? defaultMin12TimeFrom : defaultMin24TimeFrom,
        ) && _.isEqual(timeTo, ampm ? defaultMax12TimeTo : defaultMax24TimeTo)
    );
};

export const generateInputValueOnChange = (
    timeFrom: TimeRange,
    timeTo: TimeRange,
    wholeDayValue: boolean,
    ampm?: boolean,
) => {
    if (wholeDayValue) {
        return WHOLE_DAY_VALUE;
    }

    if (ampm) {
        return `${getFullUnit(timeFrom.hours)}:${getFullUnit(
            timeFrom.minutes,
        )} ${timeFrom.hourType} - ${getFullUnit(timeTo.hours)}:${getFullUnit(
            timeTo.minutes,
        )} ${timeTo.hourType}`;
    }

    return `${getFullUnit(timeFrom.hours)}:${getFullUnit(
        timeFrom.minutes,
    )} - ${getFullUnit(timeTo.hours)}:${getFullUnit(timeTo.minutes)}`;
};

export const generateTimeRangeUsingMatrix = (
    inputValue: string,
    matrix: TimeMatrix,
) => {
    return {
        hours: getSmallUnit(
            checkValidity(
                inputValue.substring(matrix.hours[0], matrix.hours[1]),
            ),
        ),
        minutes: getSmallUnit(
            checkValidity(
                inputValue.substring(matrix.minutes[0], matrix.minutes[1]),
            ),
        ),
        hourType: checkValidity(
            inputValue.substring(matrix.hourType[0], matrix.hourType[1]),
        ),
    };
};

export const prepareDefaultRangeDate = (
    startTime: string,
    endTime: string,
    ampm?: boolean,
) => {
    if (!ampm) {
        return `${startTime} - ${endTime}`;
    }

    const hourFrom = parseInt(startTime.slice(0, 2));
    const from =
        hourFrom >= 12
            ? (hourFrom === 12
                  ? hourFrom
                  : getFullUnit((hourFrom - 12).toString())) +
              startTime.slice(2) +
              ' PM'
            : `${startTime} AM`;

    const hourTo = parseInt(endTime.slice(0, 2));
    const to =
        hourTo >= 12
            ? (hourTo === 12 ? hourTo : getFullUnit((hourTo - 12).toString())) +
              endTime.slice(2) +
              ' PM'
            : `${endTime} AM`;

    return `${from} - ${to}`;
};

export const convertRangeToStartEndTime = (value: string, ampm?: boolean) => {
    const valuesMatrix = ampm ? time12Matrix : time24Matrix;
    const timeFrom = generateTimeRangeUsingMatrix(value, valuesMatrix.timeFrom);
    const timeTo = generateTimeRangeUsingMatrix(value, valuesMatrix.timeTo);

    return {
        startTime: `${
            timeFrom.hourType === 'PM' && timeFrom.hours !== '12'
                ? parseInt(timeFrom.hours) + 12
                : getFullUnit(timeFrom.hours)
        }:${getFullUnit(timeFrom.minutes)}`,
        endTime: `${
            timeTo.hourType === 'PM' && timeTo.hours !== '12'
                ? parseInt(timeTo.hours) + 12
                : getFullUnit(timeTo.hours)
        }:${getFullUnit(timeTo.minutes)}`,
    };
};
