import { makeStyles } from '@material-ui/core/styles';
import { ConfirmMfaConfigResponse } from '../../store/actions/auth/payloads';
import { Colors } from '../../styles/Colors';


export enum Reset2FADialogObjectType {
    Individual = 'individual',
    Customer = 'customer'
}

export interface Reset2FADialogProps {
    isOpen: boolean;
    object: Reset2FADialogObjectType;
    i_object: number;
    mfaError?: ConfirmMfaConfigResponse | null;
    onClose?: (success: boolean) => void;
}

export const useStyles = makeStyles(() => ({
    header: {
        '& h2': {
            fontSize: 18,
            fontWeight: 700,
        },
    },

    itemsContainer: {
        backgroundColor: Colors.SmokeBackground,
        padding: '61px 65px 83px 65px',
        display: 'flex',
        flexDirection: 'column',
        '& .MuiFormControl-root': {
            width: '100%',
            marginBottom: 24,
        },
        '& .MuiGrid-root': {
            width: 531,
        },
    },

    root: {
        '& .MuiDialogContent-root': {
            padding: 0,
        },
        '& .MuiGrid-item': {
            marginBottom: 10,
        },
    },

    disabledButton: {
        backgroundColor: Colors.Gray8,
        '& .MuiButton-label': {
            color: `${Colors.Gray10}`,
        },
    },

    primaryButton: {
        background: Colors.Primary,
        '& .MuiButton-label': {
            paddingLeft: 10,
            paddingRight: 10,
            color: `${Colors.White}`,
        },
        '&:hover': {
            cursor: 'pointer',
            background: Colors.Primary + ' !important',
        },
    },

    maxAttemptsReached: {
        fontWeight: 400,
        fontSize: 14,
        textAlign: 'center',
        color: Colors.Error,
        width: 372
    }
}));